<template>
  <div class="headerLeft" :class="{ hasIcon: icon }">
    <span v-if="icon" class="icon" :class="icon"></span>
    {{ title }}
    <span v-if="more" class="more" v-on:click="$emit('click')"></span>
  </div>
</template>

<script>
export default {
  name: "headerLeft",
  props: {
    title: String,
    icon: {
      type: String,
      default: "",
    },
    more: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/variables.scss";
.headerLeft {
  margin-top: 20px;
  color: #e50011;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0;
  border-bottom: 1px solid $module-title-border;
  position: relative;
  &.hasIcon {
    padding-left: 36px;
  }
  .more {
    width: 21px;
    height: 18px;
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 0;
    background: url("../../assets/image/icon-more.png") no-repeat center left;
    background-size: cover;
  }
  .icon {
    width: 27px;
    height: 30px;
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
    &.icon-meeting {
      background: url("../../assets/image/icon-meeting.png") no-repeat center
        left;
      background-size: contain;
    }
    &.icon-case {
      background: url("../../assets/image/icon-case.png") no-repeat center left;
      background-size: contain;
    }
    &.icon-news {
      background: url("../../assets/image/icon-news.png") no-repeat center left;
      background-size: contain;
    }
    &.icon-class {
      background: url("../../assets/image/icon-class.png") no-repeat center left;
      background-size: contain;
    }
    &.icon-club {
      background: url("../../assets/image/icon-club.png") no-repeat center left;
      background-size: contain;
    }
    &.icon-book {
      background: url("../../assets/image/icon-book.png") no-repeat center left;
      background-size: contain;
    }
  }
}
</style>
