import $http from '@/utils/request';
import $apis from './api';

export const getTagcloud = () => {
  return $http.get($apis.tagcloud).then(res => {
    return {
      data: res.data.map(i => {
        const packeg = i.packeg.split(',');
        const style = {};
        if (packeg.indexOf('大字号') > -1) style.fontSize = '23px';
        if (packeg.indexOf('粗体') > -1) style.fontWeight = 700;
        return { ...i, style };
      }),
    };
  });
};

export const getSpecial = () => {
  return $http.get($apis.special).then(res => {
    res.data.forEach(item =>
      item.forEach(i => {
        i.Id += '';
      })
    );

    return res;
  });
};

export const getBanner = params => {
  return $http.get($apis.banner, { params: { limit: 8, ...params } });
};
export const getMagazine = params => {
  return $http.get($apis.academ, { params });
};
export const getMagazineDetail = params => {
  return $http.get($apis.academ_detail, { params });
};
export const getNotice = () => {
  return $http.get($apis.notice);
};
export const getToken = () => {
  return $http.get($apis.getToken);
};

export const getTopMemberLevel = () => {
  return $http.get($apis.topMemberLevel);
};
export const getSearchassist = params => {
  return $http.get($apis.searchassist, { params }).then(res => {
    return res.data.map(i => ({ value: i.BrandName, logo: i.BrandLogo }));
  });
};
export const getInfoSearchKey = params => {
  return $http.get($apis.informationSearchKey, { params }).then(res => {
    return res.data.map(i => ({ value: i.Title }));
  });
};

export const getExternalLink = () => {
  return $http.get($apis.externalLink)
};
