<template>
  <div class="sec-nav">
    <template v-if="page==='home'">
      <a class="item" :href="item.link" v-for="(item, index) of list" :key="index">{{ item.name }}</a>
    </template>
    <template v-else>
      <a class="item" :href="item.link" v-for="(item, index) of clinicalList" :key="index">{{ item.name }}</a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SecNav',
  props: {
    page: {type: String, default: 'home'}
  },
  data() {
    return {
      list: [
     
     
        { id: '03', name: '热点资讯', link: '#news' },
		{ id: '02', name: '培训课堂', link: '#train' },
		{ id: '01', name: '会议资讯', link: '#meeting' },
        // { id: '04', name: '侃侃病例', link: '#cases' },
        { id: '05', name: '美迪会', link: '#mims' },
      ],
    };
  },
  computed: {
    clinicalList () {
      return this.list.slice(0, this.list.length - 1)
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
.sec-nav {
  height: 37px;
  line-height: 37px;
  text-align: center;
  border-top: 2px solid $theme-red;
  border-bottom: 2px solid $theme-red;
  .item {
    font-size: 14px;
    padding: 0 60px 0 12px;
    position: relative;
    color: $font-sec;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $theme-red;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
}
</style>
