<template>
  <div class="topImg">
    <div class=""><img :src="topProduct.ImagePath" alt="" class="img-cover full-w" /></div>
    <div class="color-primary fwb mv2 fs15">{{ topProduct.Introduction }}</div>
  </div>
</template>

<script>
export default {
  name: 'topImg',
  data() {
    return {
      topProduct: {},
    };
  },
  created() {
    this.$apis.ad.getAuthorityAd().then(res => {
      this.topProduct = res.data[0] || {};
    });
  },
};
</script>

<style lang="scss" scoped></style>
