import previewService from '../utils/httputils';

export const getMeetingDetail = (id) => {
  return previewService.get("/prev/meeting/"+id);
};
export const getInformationDetail = (id) => {
  return previewService.get("/prev/information/"+id);
};

export const getDiseaseDetail=(id)=>{
  return previewService.get("/prev/disease/"+id);
}

export const getMeidihuiDetail=(id)=>{
  return previewService.get("/prev/meidihui/"+id);
}

export const getTrainDetail=(id)=>{
  return previewService.get("/prev/trainInfo/"+id);
}

export const getProductDetail=(id)=>{
  return previewService.get("/prev/product/"+id);
}

export const getMagazineDetail = (id) =>{
  return previewService.get("/prev/academ_detail/"+id);
}
