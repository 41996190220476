import $http from '@/utils/request';
import $apis from './api';

export const getMeetings = params => {
  return $http.get($apis.meeting, { params });
};
export const getHotMeetings = params => {
  return $http.get($apis.hotmeeting, { params });
};
export const getMeetingDetail = params => {
  return $http.get($apis.meetingDetail, { params });
};
