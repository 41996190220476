<template>
  <div id="app">
    <HomeHeader />
    <div class="main-inner main-content">
      <slot />
      <NavLeft></NavLeft>
      <NavRight :class="{active: showRightNav }" :adAreaId="adShopMallAreaId"></NavRight>
    </div>
    <HomeFooter />
  </div>
</template>
<script>
import HomeHeader from '../components/common/HomeHeader/index';
import HomeFooter from '../components/common/HomeFooter';
import NavLeft from '../components/common/NavLeft';
import NavRight from '../components/common/NavRight';
export default {
  name: '',
  components: { HomeHeader, HomeFooter, NavLeft, NavRight },
  props: {
    adShopMallAreaId: { type: Number },
  },
  data() {
    return {
      showRightNav: false
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted () {
    let _this = this
    setTimeout(function () {
      // window.addEventListener('scroll', this.winScroll)
      if (document.addEventListener) { //火狐使用DOMMouseScroll绑定
        document.addEventListener('DOMMouseScroll', _this.winScroll, false);
      }
      //其他浏览器直接绑定滚动事件
      window.onmousewheel = document.onmousewheel = _this.winScroll;
    }, 500)
  },
  unmounted () {
    window.removeEventListener('scroll', this.winScroll)
  },
  methods: {
    winScroll (e) {
      e = e || window.event;
      if (e.wheelDelta) {  //判断浏览器IE，谷歌滑轮事件
        if (e.wheelDelta > 0) { //当滑轮向上滚动时
          this.showRightNav = true
        }
        if (e.wheelDelta < 0) { //当滑轮向下滚动时
          this.showRightNav = false
        }
      } else if (e.detail) {  //Firefox滑轮事件
        if (e.detail> 0) { //当滑轮向上滚动时
          this.showRightNav = true
        }
        if (e.detail< 0) { //当滑轮向下滚动时
          this.showRightNav = false
        }
      }

    }
  },
};
</script>
<style lang="scss" scoped>
  .main-content {
    min-height: calc(100vh - 760px);
  }
</style>
