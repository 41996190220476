<template>
  <ArticleModule icon="icon-meeting" title="会议资讯" :showMore="showMore" :href="listUrl">
    <div v-loading="loading">
      <template v-if="meetingList.length">
        <MeetingItem v-for="(item, idx) in meetingList" :key="idx" :item="item" />
        <el-pagination
          class="tc"
          v-if="showPagination"
          :showPagination="showPagination"
          :total="total"
          :pageSize="pageSize"
          layout="prev, pager, next"
          @current-change="onPageChange"
        ></el-pagination>
      </template>
      <div class="no-data" v-else>暂无数据</div>
    </div>
  </ArticleModule>
</template>
<script>
import ArticleModule from './article/Index';
import MeetingItem from './MeetingItem';

export default {
  components: { ArticleModule, MeetingItem },
  props: {
    showPagination: { type: Boolean, default: false },
    pageSize: { type: Number, default: 5 },
    showMore: { type: Boolean, default: true },
    params: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      meetingList: [],
      total: 0,
      loading: true,
      page: 1,
    };
  },
  computed: {
    listUrl() {
      // eslint-disable-next-line no-unused-vars
      const { limit, ...rest } = this.params;
      const query = this.$utils.obj2query(rest);
      return `${this.$constants.routers.meeting.link}?${query}`;
    },
  },
  watch: {},
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    onPageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      // this.$store.dispatch('user/getUserInfo').then(({ Department }) => {
      this.$apis.meeting
        .getMeetings({
          limit: 3,
          page: this.page,
          ...this.params,
        })
        .then(res => {
          this.meetingList = res.data;
          this.total = res.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      // });
    },
    go2Meeting() {
      console.log('go2Meeting-click');
      location.href = this.$constants.routers.meeting.link;
    },
  },
};
</script>
<style lang="scss" scoped></style>
