<template>
  <div class="disease-detail">
    <div class="detail">
      <div class="pic">
        <img :src="detail.ImageFileName" alt="" class="img full-h" />
      </div>
      <div class="content" flex="cross:center">
        <div>
          <div class="title">{{ detail.Name }}</div>
          <!-- <div class="sub-title">CONSTIPATION IN ADULTS - CHRONIC FUNCTIONAL</div> -->
          <div class="info" v-html="detail.Details"></div>
        </div>
      </div>
    </div>
    <div class="links">
      <div class="back">
        <a href="javascript:void(0)" onClick="javascript:history.back(-1)">
          <svg-icon icon-class="arrleft" />返回{{ specialtyName }}
        </a>
      </div>
      <div class="forward">
        <a class="db" :href="pdfUrl">
          <svg-icon class="book" icon-class="book"/>
          查看疾病用药指南PDF<svg-icon icon-class="arrright"></svg-icon>
        </a>
      </div>
    </div>
    <el-tabs @tab-click="handleClick" style="height: 53px;">
      <!--<el-tab-pane name="first">-->
        <!--<a slot="label" href="#top"><svg-icon icon-class="home" /> 疾病主页</a>-->
      <!--</el-tab-pane>-->
      <!--<el-tab-pane name="second">-->
        <!--<a slot="label" href="#cases"><svg-icon icon-class="case" /> 侃侃病例</a>-->
      <!--</el-tab-pane>-->
      <el-tab-pane name="third">
        <a slot="label" href="#drug"><svg-icon icon-class="drug" /> 药品信息</a>
      </el-tab-pane>
      <el-tab-pane name="fourth">
        <a slot="label" href="#news"><svg-icon icon-class="news" />新闻资讯</a>
      </el-tab-pane>
      <el-tab-pane name="last">
        <a slot="label" href="#reference"><svg-icon icon-class="news" />参考文献</a>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    id: { type: [String, Number] },
    detail: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      activeName: 'first',
    };
  },
  computed: {
    pdfUrl() {
      return this.detail.pdfpath?`${this.$constants.routers.pdfDetail.link}?url=${encodeURIComponent(this.detail.pdfpath)}` : '';
    },
    specialtyName() {
      return (this.$store.getters['app/departmentsMap'][this.id] || {}).Name;
    },
  },
  methods: {
    handleClick() {},
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables';
.svg-icon {
  width: 24px;
  height: 16px;
  color: #888;
}
.is-active {
  .svg-icon {
    color: $theme-red;
  }
}
::v-deep .el-tabs__nav {
  display: flex;
  width: 100%;
}
::v-deep .el-tabs__item {
  height: 53px;
  line-height: 53px;
  flex: 1;
  font-size: 15px;
}
::v-deep .el-tabs__item.is-active {
  color: $theme-color;
  font-weight: bold;
}
::v-deep .el-tabs__active-bar {
  background: transparent;
}
::v-deep .el-tabs__nav-wrap::after {
  background: $theme-color;
}
.disease-detail {
  padding: 20px 0;
  .detail {
    height: 340px;
    overflow: hidden;
    display: flex;
    .pic {
      width: 306px;
      .img {
        width: 100%;
      }
    }
    .content {
      flex: 1;
      color: #000;
      padding: 0 20px;
      .title {
        font-size: 15px;
        font-weight: bold;
        padding-top: 30px;
      }
      .sub-title {
        font-weight: bold;
      }
      .info {
        line-height: 18px;
        padding: 20px 0;
      }
    }
  }
  .links {
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    font-size: 15px;
    color: #fff;
    background: $theme-color;
    display: flex;
    cursor: pointer;
    .svg-icon {
      color: #fff;
    }
    a {
      color: #fff;
    }
    .forward {
      flex: 1;
      text-align: right;
      .book {
        position: relative;
        transform: scale(2);
        margin-right: 20px;
        top: -1px;
      }
    }
  }
}
</style>
