<template>
  <div class="user tc pb8">
    <Avatar :value="userInfo.Avatar" @change="changeAvatar" />
    <div class="mb4 mt2" style="height:40px">
      <p class="name line1">{{ userInfo.Name }}</p>
      <p class="number line1" v-show="userInfo.Uid">MIMS号：{{ userInfo.Uid }}</p>
    </div>
    <ul class="menu">
      <li class="menu-item mt2" :class="{ current: getCurrent(item.link) }" v-for="item in menu" :key="item.link">
        <a :href="item.link">{{ item.label }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import Avatar from '@/components/avatar';
import { mapState, mapActions } from 'vuex';
export default {
  components: { Avatar },
  data() {
    return {
      menu: ['mine', 'integral', 'fav', 'address'].map(i => this.$constants.routers[i]),
    };
  },
  computed: {
    ...mapState({ userInfo: state => state.user.userInfo }),
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),
    getCurrent(link) {
      return location.pathname === link;
    },
    changeAvatar(avatar) {
      this.$apis.user.editInfo({ avatar }).then(() => {
        this.$message.success('更换成功');
        this.getUserInfo({ force: true });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  height: 30px;
  line-height: 30px;
  &.current {
    background: #fff;
  }
}
.user {
  width: 220px;
  background: #ececec;
  font: 14px '';

  .d2 {
    margin-top: 20px;
  }
  .d3 {
    li {
      margin-top: 48px;
    }
  }
}
</style>
