import $apis from '@/apis';
import { USER_SESSION_KEY, routers } from '@/constants';
import { setCache, setState } from './helper';

export default {
  namespaced: true,
  state: {
    userInfo: {},
    pointRuleMap: {},
  },
  getters: {},
  mutations: {
    setState,
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setPointRules(state, payload) {
      state.pointRuleMap = payload;
    },
  },
  actions: {
    doLogin({ commit }, payload) {
      return $apis.user.doLogin(payload).then(res => {
        commit('setUserInfo', res.data);
        localStorage.setItem(USER_SESSION_KEY, res.data.api_token);
        return res.data;
      });
    },
    getUserInfo({ commit, state }, payload) {
      const { force = false } = payload || {};

      if (force === false && state.userInfo.Uid) {
        setCache({ commit, key: 'userInfo', cache: state.userInfo });
        return Promise.resolve(state.userInfo);
      }
      return $apis.user.getUserInfo().then(res => {
        commit('setUserInfo', res.data);
        return res.data;
      });
    },
    getPointRules({ commit }) {
      return $apis.user.getPointRules().then(res => {
        commit(
          'setPointRules',
          res.data.reduce((map, item) => {
            map[item.RuleType] = item;
            return map;
          }, {})
        );
      });
    },
    logout(context, payload = {}) {
      const { redirect = routers.index.link } = payload;
      $apis.user.doLogout().then(() => {
        localStorage.removeItem(USER_SESSION_KEY);
        setTimeout(() => {
          location.href = redirect;
        }, 1000);
      });
    },
  },
};
