import $http from '@/utils/request';
import $apis from './api';

export const getNews = params => {
  return $http.get($apis.news, { params }).then(res => {
    const result = res.data.map(item => {
      return { ...item, Keywords: item.Keywords || '' };
    });

    return {
      data: result,
      total: res.total,
    };
  });
};
export const getNewsDetail = params => {
  return $http.get($apis.newsDetail, { params });
};
