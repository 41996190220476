<template>
  <div class="list-item ph2 csp" @click="go2detail">
    <div class="list-item-inner rel">
      <div class="content abs " :style="{ backgroundImage: 'url(' + item.Cover + ')' }">
        <div class="item abs">
          <div class="line3">
            <span class="fwb black">{{ tip }}：</span>
            <span class="gray6">{{ item.Introduce || item.SubTitle }}</span>
          </div>
          <el-button type="primary" class="exchange abs" round @click.stop="go2detail">查看</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    tip: { type: String, default: '主讲内容' },
    item: { type: Object, default: () => ({}) },
    toDetail: { type: String, default: '' }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    go2detail() {
      if (this.toDetail) {
        location.href = `${this.toDetail}?type=train&id=${this.item.Id}`;
      } else {
        location.href = `${this.$constants.routers.shopDetail.link}?id=${this.item.Id}`;
      }
    },
    go2confirm() {
      location.href = `${this.$constants.routers.shopConfirm.link}?id=${this.item.Id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.list-item {
  width: 280px;
  height: 320px;
  .content {
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
	background-size: 100%;
    .item {
      background: #fff;
      box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.22);
      left: 6px;
      right: 6px;
      bottom: 0;
      padding: 20px 12px 30px;
      font: 14px '';
      i {
        color: #333;
        font-weight: 700;
      }
      span {
        color: #888;
      }
    }
  }
}
.list-item-inner {
  height: 320px;
}
.exchange {
  left: 0;
  bottom: 0px;
  width: 100%;
  transform: translate(0, 50%);
}
</style>
