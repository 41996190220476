<template>
  <!-- 参考文献 -->
  <div class="literature mb2">
    <ModuleHeader class="mb4" title="参考文献" icon="reference" :showMore="false"></ModuleHeader>
    <div class="list gray6 fs14 oa">
      <div class="item mt1" v-for="(item, index) of list" :key="index" flex="cross:top">
        <span class="mr1">-</span>
        <p>{{ item.ReferenceDisplay }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleHeader from '../../components/common/ArtHeaderLeft';
export default {
  name: 'Literature',
  components: { ModuleHeader },
  props: {
    list: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.literature {
  margin: 0 auto;
  .literature-top {
    border-bottom: 1px solid #d9d9d9;
    font: 600 20px/2 '';
    color: #e60012;
    img {
      margin-bottom: 3px;
    }
    span {
      margin-left: 9px;
    }
  }

  .list {
    height: 419px;
    margin-top: 20px;
  }
}
</style>
