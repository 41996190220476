<template>
  <!--左侧模块-通用header-->
  <div class="module-header" :class="{ hasIcon: icon }" flex="main:justify">
    <div flex="cross:center">
      <span v-if="icon" class="icon" :class="icon"></span>
      <svg-icon v-if="svgIcon" class="svg-icon mr1" :icon-class="svgIcon"></svg-icon>
      {{ title }}
      <span class="sub fwb fs14 black" v-if="subTitle">
        <span class="mh2">|</span>
        <span>{{ subTitle }}</span>
      </span>
    </div>
    <div flex="cross:center">
      <slot />
      <slot name="more">
        <span v-if="showMore" class="more csp ml2" v-on:click="$emit('click')"></span>
      </slot>
    </div>
    <!--<span v-if="showMore" class="more csp" v-on:click="$emit('click')"></span>-->
  </div>
</template>

<script>
export default {
  name: 'ModuleHeader',
  props: {
    title: String,
    subTitle: { type: String, default: '' },
    icon: {
      type: String,
      default: '',
    },
    svgIcon: {
      type: String,
      default: '',
    },
    more: {
      type: Boolean,
      default: true,
    },
    showMore: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables';
.module-header {
  color: $module-title;
  font-size: 15px;
  font-weight: bold;
  padding: 8px 0;
  margin-top: 22px;
  border-bottom: 1px solid $module-title-border;
  position: relative;
  &.hasIcon {
    padding-left: 24px;
  }
  .more {
    width: 15px;
    height: 12px;
    display: inline-block;
    /*position: absolute;*/
    /*top: 15px;*/
    /*right: 0;*/
    background: url('../../assets/image/icon-more.png') no-repeat center left;
    background-size: cover;
  }
  .icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    top: 7px;
    left: 0;
    &.icon-meeting {
      background: url('../../assets/image/icon-meeting.png') no-repeat center left;
      background-size: contain;
    }
    &.icon-case {
      background: url('../../assets/image/icon-case.png') no-repeat center left;
      background-size: contain;
    }
    &.icon-news {
      background: url('../../assets/image/icon-news.png') no-repeat center left;
      background-size: contain;
    }
    &.icon-class {
      background: url('../../assets/image/icon-class.png') no-repeat center left;
      background-size: contain;
    }
    &.icon-club {
      background: url('../../assets/image/icon-club.png') no-repeat center left;
      background-size: contain;
    }
    &.icon-diseases {
      background: url('../../assets/image/icon-diseases.png') no-repeat center left;
      background-size: contain;
    }
    &.icon-release {
      background: url('../../assets/image/icon-release.png') no-repeat center left;
      background-size: contain;
    }
    &.icon-selected {
      background: url('../../assets/image/icon-selected.png') no-repeat center left;
      background-size: contain;
    }
    &.typicalCases {
      background: url('../../assets/image/typicalCases.png') no-repeat center left;
      background-size: contain;
    }
    &.new-prod {
      background: url('../../assets/image/icon-new-pord.png') no-repeat center;
      background-size: 20px 20px;
    }
    &.icon-book {
      background: url('../../assets/image/icon-book.png') no-repeat center left;
      background-size: contain;
    }
    &.icon-drug {
      background: url('../../assets/image/icon-drug.png') no-repeat center left;
      background-size: contain;
    }
    &.reference {
      background: url('../../assets/image/shu.png') no-repeat center left;
      background-size: contain;
    }
  }
}
</style>
