<template>
  <div class="item" flex>
    <div flex-box="0" class="pic-w oh mr3 csp" @click="go2detail">
      <div class="pic-i">
        <img :src="item.Cover" flex-box="0" class="img-placeholder csp" @click="go2detail" />
        <!--<div class="list-item-tag" v-if="tag">{{ tag }}</div>-->
      </div>
    </div>
    <div class="content rel pl3" flex-box="1">
      <div class="title line2 fs15 csp" @click="go2detail">{{ item.Title }}</div>
      <div class="art-content line2 fs14 csp" @click="go2detail">{{ item.SubTitle }}</div>
      <div class="bottom fs12" flex>
        <div class="left" flex-box="1" flex="cross:center">
          <div v-if="item.specialty.length>0" class="line1">所属领域：{{ (item.specialty || []).map(i => i.Name).join('，') }}</div>
        </div>
        <div class="tr ml2" flex-box="0">
          <span @click.stop class="csp"><Fav :type="4" :id="item.Id" :status="item.is_favoite"/></span>
          <span class="ml2"><span class="el-icon-time mr1"></span>{{ $utils.formatTime(item.ReleaseDate) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Fav from '@/components/fav';

export default {
  props: {
    item: { type: Object, default: () => ({}) },
  },
  components: { Fav },
  data() {
    const { id } = this.$utils.query2Obj(location.search);
    return { id };
  },
  computed: {
    tag() {
      let result = '';
      if (location.pathname === this.$constants.routers.specialty.link) {
        result = (this.$store.getters['app/departmentsMap'][this.id] || {}).Name;
      }

      return result;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    go2detail() {
      location.href = `${this.$constants.routers.newsDetail.link}?type=disease&id=${this.item.Id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/variables.scss';
.bottom {
  position: absolute;
  left: 20px;
  bottom: 0;
  right: 0;
  color: $font-content;
  padding: 10px 0 0;
  display: flex;
}
.item {
  display: flex;
  overflow: hidden;
  padding: 15px 0;
  .content {
    .title {
      color: $font-title;
      font-weight: bold;
      margin-bottom: 6px;
    }
    .art-content {
      color: $font-content;
      /*line-height: 22px;*/
    }
  }
}
.item {
  &:last-child {
    margin: 0 !important;
  }
}
</style>
