import $http from '@/utils/request';
import $apis from './api';

export const getAds = params => {
  return $http.get($apis.advert, { params });
};

export const getAuthorityAd = params => {
  return $http.get($apis.authorityAd, { params });
};

export const getMimsFocus = params => {
  return $http.get($apis.mimsfocus, { params });
};

export const getNewFocusList = params => {
  return $http.get($apis.newFocusList, { params });
};

export const getShopEntry = params => {
  return $http.get($apis.shopEntry, { params });
};
