<template>
  <div class="latest-meeting">
    <article-header title="最热会议" @click="go2meeting" :more="showMore"></article-header>
    <div class="list" v-loading="loading">
      <template v-if="list.length">
        <div class="item" v-for="(item, index) of list" :key="index">
          <div class="content line2 csp" @click="go2detail(item)">{{ item.Title }}</div>
          <div class="info">
            <span class="time">{{ $utils.formatTime(item.ReleaseDate) }}</span>
            <!--
            <span class="status" :class="'status' + item.Status">{{ status[item.Status] }}</span>
            -->
          </div>
        </div>
        <div class="list-more csp" v-if="showMore" @click="go2meeting">查看更多</div>
      </template>
      <div class="no-data" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
import ArticleHeader from '../common/ArtHeaderRight';
export default {
  name: 'LatestMeeting',
  components: { ArticleHeader },
  props: {
    showMore: { type: Boolean, dafault: true },
    params: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      // 0编辑,1待审核,2已审核,3上线,4下线
      status: ['编辑中', '待审核', '已审核', '进行中', '已结束'],
      list: [],
      loading: true,
    };
  },
  computed: {},
  created() {
    this.$apis.meeting
      .getHotMeetings({ limit: 5, ...this.params })
      .then(res => {
        this.list = res.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    go2meeting() {
      // eslint-disable-next-line no-unused-vars
      const { limit, ...rest } = this.params;
      const query = this.$utils.obj2query(rest);
      location.assign(`${this.$constants.routers.meeting.link}?${query}`);
    },
    go2detail(item) {
      location.assign(`${this.$constants.routers.newsDetail.link}?type=meeting&id=${item.Id}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
@import '../../assets/css/mixin.scss';
.latest-meeting {
  .header {
    display: flex;
    font-size: 22px;
    border-bottom: 1px solid #ccc;
    .title {
      padding: 6px 0;
      border-bottom: 4px solid $theme-color;
    }
    .more {
      flex: 1;
      text-align: right;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .list {
    padding: 10px 0;
    .item {
      padding: 10px 0;
      .content {
        font-size: 12px;
        line-height: 22px;
        color: $font-main;
      }
      .info {
        color: $font-sec;
        font-size: 12px;
        line-height: 2;
        text-align: right;
        .status {
          padding-left: 6px;
        }
        .status3 {
          color: $theme-color;
        }
        // .status2 {
        //   color: #0099ff;
        // }
      }
    }
    .list-more {
      background: #e4e4e4;
      color: $theme-color;
      height: 28px;
      line-height: 28px;
      text-align: center;
    }
  }
}
</style>
