import $http from '@/utils/request';
import $apis from './api';

export const checkAuth = params => {
  return $http.get($apis.checkAuth, { params }).then(res => {
    return {
      isFee: res.isFee,
      feeDetail: res.feeDetail
    };
  });
};

export const exchangeRes = params => {
  return $http.get($apis.exchangeRes, { params }).then(res => {
    return {
      code: res.code,
      msg:res.msg,
      isFee:res.isFee
    };
  });
};
