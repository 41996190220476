<template>
  <div class="right-nav hidden">
    <div class="item">
      <div class="box-square">
        <img src="../../assets/image/right-nav02.png" alt="" class="img" />
      </div>
      <p class="info">MIMS医药资讯</p>
    </div>
    <div class="item hidden">
      <div class="box-square">
        <img src="../../assets/image/right-nav01.png" alt="" class="img" />
      </div>
      <p class="info">MIMS公众号</p>
    </div>
    <div class="item">
      <a :href="$constants.routers.shopHome.link">
        <div class="box-square">
          <img :src="ad.ImagePath" alt="" class="img" />
        </div>
        <p class="info">如何获得积分</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightNav',
  props: {},
  data() {
    return {
      ad: {},
    };
  },
  computed: {},
  created() {
    this.$apis.ad.getShopEntry().then(res => {
      this.ad = res.data;
    });
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
@import '../../assets/css/mixin.scss';
.right-nav {
  position: fixed;
  right: -100px;
  top: 348px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  background: #fff;
  transition: 0.5s;
  &.active {
    right: 0;
  }
  .item {
    margin-bottom: 13px;
    box-sizing: border-box;
    font-size: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    width: 91px;
    text-align: center;
    .img {
      width: 100%;
    }
    .info {
      font-size: 13px;
      color: $font-main;
    }
  }
}

@media screen and (min-width: 1540px) {
  .right-nav {
    right: calc((100% - 1440px) / 2 - 50px);
    &.active {
      right: calc((100% - 1440px) / 2 - 50px);
    }
  }
}
</style>
