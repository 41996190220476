<template>
  <div class="list-header line-b pb2 mt5" flex="main:justify">
    <div flex="cross:center">
      <img class="icon img-cover mr2" :src="icon" width="18" v-if="icon" />
      <div class="title color-primary fwb fs15">{{ title }}</div>
      <div class="sub fwb fs14" v-if="subTitle" flex="cross:center">
        <span class="mh2">|</span>
        <span>{{ subTitle }}</span>
      </div>
    </div>
    <div flex="cross:center">
      <slot />
      <slot name="more">
        <div v-if="hasMore" class="ml2" @click="$emit('more-clicked')">
          <img src="@/assets/image/icon-more.png" class="csp" width="18" alt="" />
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: require('@/assets/image/icon-news.png') },
    title: { type: String, default: '' },
    subTitle: { type: String, default: '' },
    hasMore: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss"></style>
