<template>
  <el-select placeholder="请选择" class="full-w" :value="value" @change="onChange" :disabled="disabled">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
  </el-select>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [Number, String] },
    disabled: { type: Boolean, default: false}
  },
  data() {
    return {
      options: [
        { value: 1, label: '医师' },
        { value: 2, label: '药师' },
        { value: 3, label: '技师' },
        { value: 4, label: '护师' },
        { value: 5, label: '医疗媒体工作者' },
        { value: 6, label: '医疗企业工作者' },
        { value: -1, label: '其他' },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onChange(v) {
      this.$emit('change', v);
    },
  },
};
</script>
<style lang="scss" scoped></style>
