<template>
  <!--会议资讯右侧广告-->
  <div class="home-long-banner pb4 mt3">
    <a :href="ad.TargetUrl" target="_blank"><img :src="ad.ImagePath" alt="会议" class="img"/></a>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    ad: { type: Object, default: () => ({}) },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.home-long-banner {
  @include padding-height(56%);
  background: $skeleton-bg;
  .img {
    width: 100%;
  }
}
</style>
