<template>
  <a :href="detailUrl+item.Id" target="_blank">
    <div class="item" flex>
      <div flex-box="0" class="pic-w oh mr3 csp">
        <div class="pic-i">
          <img :src="item.Cover" alt="" class="full img-placeholder" />
          <!-- <div class="list-item-tag" v-if="tag">{{ tag }}</div> -->
        </div>
      </div>
      <div flex-box="1" class="rel full-w">
        <!-- {{ item }} -->
        <h3 class="fs15 line2 csp">{{ item.Title }}</h3>
        <div class="fs12 gray9 line2 mt1 mb2 csp">{{ item.SubTitle }}</div>
        <div class="gray9 bottom" flex="main:justify">
          <div flex-box="1">
            <div v-if="item.specialty.length>0" class="line1">所属领域：{{ (item.specialty || []).map(i => i.Name).join('，') }}</div>
          </div>
          <div flex="cross:center main:right" class="ml2" flex-box="0">
            <Fav :type="2" :id="item.Id" :status="item.is_favoite" />
            <span class="el-icon-time mh1"></span>
            <span>{{ $utils.formatTime(item.ReleaseDate) }}</span>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import Fav from '@/components/fav';
export default {
  name: '',
  components: { Fav },
  props: {
    item: { type: Object, default: () => ({}) },
    href: { type: String },
  },
  data() {
    // const { id } = this.$utils.query2Obj(location.search);
    return {
      detailUrl:this.$constants.routers.hotDetail.link+"?type=news&id="
    };
  },
  computed: {
    // tag() {
    //   let result = '';
    //   if (location.pathname === this.$constants.routers.specialty.link) {
    //     result = (this.$store.getters['app/departmentsMap'][this.id] || {}).Name;
    //   }
    //   return result;
    // },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    go2detail(item) {
      location.href = this.href || `${this.$constants.routers.hotDetail.link}?type=news&id=${item.Id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/variables.scss';
.item {
  display: flex;
  overflow: hidden;
  padding: 15px 0;
  .content {
    .title {
      color: $font-title;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 6px;
    }
    .art-content {
      color: $font-content;
      /*line-height: 22px;*/
    }
  }
}
.item {
  &:last-child {
    margin: 0 !important;
  }
}
.bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px 0 0;
  display: flex;
}
</style>
