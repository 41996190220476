<template>
  <!--使用中：热门点击、猜你喜欢-->
  <div class="item csp" @click="go2detail(item)">
    <div class="pic-w-r">
      <div class="pic-i-r">
        <img :src="item.Cover" alt="" class="img img-cover img-placeholder" />
      </div>
    </div>
    <div class="content">
      <div class="title line2">{{ item.Title }}</div>
      <div class="info line2">{{ item.SubTitle }}</div>
      <div class="number">
        <span v-if="showFav" @click.stop class="csp mr1"><Fav :type="3" :id="item.Id" :status="item.is_favoite"/></span>
        阅读人数：{{ item.ReadCount }}
      </div>
    </div>
  </div>
</template>
<script>
import Fav from '@/components/fav';
export default {
  name: '',
  props: {
    item: { type: Object, default: () => ({}) },
    showFav: { type: Boolean, default: false },
  },
  components: { Fav },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    go2detail(item) {
      location.href = `${this.$constants.routers.videoDetail.link}?type=train&id=${item.Id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/variables.scss';

.item {
  padding: 6.5px 0;
  display: flex;
  .pic {
    width: 31.25%;
    /*height: 75px;*/
    @include padding-height(100%);
    background: $skeleton-bg;
    .img {
      width: 100%;
    }
  }
  .content {
    flex: 1;
    font-size: 12px;
    line-height: 14px;
    color: $font-main;
    padding-left: 18px;
    .title {
      font-weight: bold;
      line-height: 14px;
    }
    .info {
      line-height: 22px;
    }
    .number {
      font-size: 12px;
      text-align: right;
      color: $font-sec;
    }
  }
}
</style>
