<template>
  <!--首页右侧产品聚焦下广告位-->
  <div class="prod-focus">
    <a :href="ad.TargetUrl" target="_blank"><img :src="ad.ImagePath" alt="产品聚焦" class="full-w img-cover"/></a>
  </div>
</template>

<script>
export default {
  name: 'ProdFocus',
  props: {
    ad: { type: Object, default: () => ({}) },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
.prod-focus {
  @include padding-height(143.75%);
  background: $skeleton-bg;
  .header {
    display: flex;
    font-size: 22px;
    border-bottom: 1px solid #ccc;
    .title {
      padding: 6px 0;
      border-bottom: 4px solid $theme-color;
    }
    .more {
      flex: 1;
      text-align: right;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>
