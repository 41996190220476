<template>
  <div class="footer">
    <div class="container main-inner">
      <img class="logo" src="../../../assets/image/logo-mini.png" alt="MIMS" />
      <span class="copy-right">
        &copy;2020 <a href="http://beian.miit.gov.cn" target="_blank">沪ICP备10001518号</a>
        <a href="http://beian.miit.gov.cn" target="_blank">药监批号(沪)-非经营-2015-0018</a>
		<a href="http://oss.mims.com.cn/mims.png" target="_blank">&nbsp;&nbsp;营业执照</a>
      </span>
      <span class="links">
        <a
          :href="item.router"
          v-for="(item, index) of footerLinks"
          :key="index" target="view_window"
        >{{ item.name }}</a>
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    data() {
      return {
        footerLinks: [
          { name: '公司简介', router: this.$constants.routers.companyProfile.link },
          { name: '使用条款', router: this.$constants.routers.terms.link },
          { name: '隐私政策', router: this.$constants.routers.privacy.link },
          { name: '安全通告', router: this.$constants.routers.securityAdvisory.link },
        ],
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../assets/css/variables';
  @import '../../../assets/css/mixin';
  .footer {
    background: $theme-red;
    color: #fff;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    .logo {
      width: 70px;
      position: relative;
      top: 4px;
    }
    .copy-right {
      font-size: 12px;
      color: #ffffff;
    }
    .copy-right a{
      color: #ffffff;
    }
    .links {
      float: right;
      a {
        cursor: pointer;
        display: inline-block;
        color: #fff;
        padding: 0 10px;
      }
    }
  }
</style>
