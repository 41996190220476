import $http from '@/utils/request';
import $apis from './api';

export const getDiseaseCases = params => {
  return $http.get($apis.diseasecases, { params });
};

export const getDiseaseDetail = params => {
  return $http.get($apis.diseaseDetail, { params });
};

export const getDiseaseList = params => {
  return $http.get($apis.diseaseList, { params });
};

export const getSpecialtyDisease = () => {
  return $http.get($apis.specialtyDisease).then(res => {
    return {
      list: res.data.map(item => {
        const { Specialty, TreatmentGuideline } = item;
        return {
          ...item,
          id: Specialty,
          label: Specialty,
          children: TreatmentGuideline.map(item => ({
            label: item.Name,
          })),
        };
      }),
      map: res.data.reduce((map, item) => {
        map[item.Specialty] = item;
        return map;
      }, {}),
    };
  });
};
