<template>
  <div class="article">
    <module-header
      v-if="showHeader"
      :title="title"
      :icon="icon"
      :showMore="showMore"
      @click="handClick"
    ></module-header>
    <div class="list mt3 oa" :style="{ maxHeight }">
      <slot>
        <MeetingItem v-for="(item, idx) in list" :key="idx" :item="item" />
      </slot>
    </div>
    <div v-if="showPagination" class="mb2 tc">
      <el-pagination
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        @current-change="page => $emit('current-change', page)">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import ModuleHeader from '../common/ArtHeaderLeft';
import MeetingItem from '../MeetingItem';
export default {
  name: 'Article',
  components: { ModuleHeader, MeetingItem },
  props: {
    maxHeight: { type: String, default: 'unset' },
    showHeader: { type: Boolean, default: true },
    showMore: { type: Boolean, default: true },
    // icon-meeting; icon-case;icon-class
    icon: { type: String, default: '' },
    title: { type: String, default: '会议资讯' },
    // 点击更多跳转的页面
    href: { type: String, default: 'home.html' },
    total: { type: Number },
    showPagination: { type: Boolean, default: false },
    pageSize: {type: Number, default: 5},
    list: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  methods: {
    handClick() {
      location.assign(this.href);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
.article {
  /*width: 840px;*/
  .header {
    color: $theme-color;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5;
    border-bottom: 1px solid $theme-color;
  }
  .list {
  }
}
</style>
