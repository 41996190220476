import Vue from 'vue';
import ElementUI from 'element-ui';
import './styles/index.scss';
import * as constants from './constants';
import './icons'; // icon
import store from './store';
import $http from '@/utils/request';
import $apis from '@/apis/index';
import * as utils from '@/utils';
import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$constants = constants;
Vue.prototype.$http = $http;
Vue.prototype.$apis = $apis;
Vue.prototype.$utils = utils;
Vue.prototype.$store = store;

Vue.config.errorHandler = function(err, vm, info) {
  console.error(err, vm, info);
};

export const setup = App => {
  new Vue({
    store,
    render: h => h(App),
    mounted () {
      document.dispatchEvent(new Event('render-event'))
    }
  }).$mount('#app');
};
