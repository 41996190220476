<template>
  <div class="new-prod-focus">
<!--    <article-header title="新产品聚焦" @click="go2NewProd"></article-header> -->
    <el-carousel class="my-carousel" trigger="click" height="300px" :autoplay="true">
      <el-carousel-item
        class="item csp"
        v-for="(item, index) in list"
        :key="index"
        @click.native="go2drug(item)">
        <a class="info gray-link">
          <p class="fwb fs14">{{ item.name }}</p>
          <p>{{ item.introduction }}</p>
          <p>{{ item.characteristic }}</p>
        </a>
        <img :src="item.imagepath" class="img img-cover" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
// import ArticleHeader from '../common/ArtHeaderRight';
export default {
  name: 'NewProdFocus',
  // components: { ArticleHeader },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$apis.ad.getNewFocusList({ limit: 8 }).then(res => {
      this.list = res.data.slice(0, 8);
    });
  },
  methods: {
    go2NewProd() {
      location.assign(this.$constants.routers.newProdFocus.link);
    },
    go2drug(item) {
      location.href = item.productUrl || '';
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
.new-prod-focus {
  background: #fff;
  min-height: 208px;
  display: flex;
  flex-direction: column;
  .my-carousel {
    flex: 1;
    overflow-y: hidden;
    .item {
      background: #fff;
      height: 100%;
      background: #fff;
      .info {
        position: absolute;
        top: 10px;
        width: 300px;
        font-size: 12px;
        line-height: 1.5;
        li {
          text-indent: 2em;
        }
      }
      .img {
        position: absolute;
        bottom: 10px;
        /*right: 25%;*/
        width: 300px;
        height: 200px;
      }
    }
  }
  ::v-deep {
    .el-carousel__button {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #b7b7b7;
    }
    .is-active {
      .el-carousel__button {
        background: $theme-red;
      }
    }
  }
}

::v-deep .el-carousel__button {
  background: #b7b7b7;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
