const reg_mobile = /^1[3,4,5,6,7,8,9]{1}[0-9]{1}[0-9]{8}$/;
const reg_password = /(?=.*\d)(?=.*[A-Z])(?=.*[a-z])/;
const reg_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const validMobile = (rule, value, callback) => {
  if (!value) {
    callback();
  } else if (reg_mobile.test(value)) {
    callback();
  } else {
    callback(new Error('格式错误，请输入正确手机号码'));
  }
};

export const validPassword = (rule, value, callback) => {
  if (!value) {
    callback();
  } else if (!reg_password.test(value)) {
    callback(new Error('密码至少包含一个大写字母、一个小写字母和一个数字'));
  } else {
    callback();
  }
};

export const required = () => ({ required: true, message: '必填项', trigger: 'blur' });

export const validEmail = (rule, value, callback) => {
  if (!value) {
    callback();
  } else if (!reg_email.test(value)) {
    callback(new Error('请输入正确的邮箱地址'));
  } else {
    callback();
  }
};

export const createMinLength = plyload => (rule, value, callback) => {
  const { num, tip = `至少${num}位` } = plyload;
  const length = value.length;
  // value没填时可以通过校验，是否填写应该由required去校验
  if (!value) {
    callback();
  } else if (length < num) {
    callback(new Error(tip));
  } else {
    callback();
  }
};

export const createRangeLength = plyload => (rule, value, callback) => {
  const { min, max, label } = plyload;
  const length = value.length;
  // value没填时可以通过校验，是否填写应该由required去校验
  if (!value) {
    callback();
  } else if (length < min) {
    callback(new Error(`${label}长度至少${min}位`));
  } else if (length > max) {
    callback(new Error(`${label}长度最多${max}位`));
  } else {
    callback();
  }
};

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}
