<template>
  <div>
    <div class="banner">
      <el-carousel :interval="4000" type="card" height="92%">
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <a :href="item.TargetUrl" class="medium full rel" target="_blank">
            <img :src="item.ImagePath" alt="" class="full img-cover" />
          </a>
          <div class="banner-title">{{ item.Description }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$apis.app.getBanner({ AscriptionId: 3, ResRefId: 0 }).then(res => {
      this.list = res.data;
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel .el-carousel__container {
  background: transparent;
}
.banner {
  @include padding-height(50%);
  > * {
    overflow: hidden;
  }
}
.el-carousel__item {
  text-align: center;
}
.el-carousel .el-carousel__container img {
  width: 283px;
  height: 386px;
  box-shadow: 0px 0px 11px 1px rgba(51, 51, 51, 0.77);
}
.banner-title {
  width: 283px;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
;
}
</style>
