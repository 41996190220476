import axios from 'axios';
import { Message } from 'element-ui';
import { USER_SESSION_KEY } from '../constants';
import { paramsNonEmptyFilter, checkGoLogin } from '@/utils';
import apiConfig from '../config/mims.config';
// create an axios instance
const service = axios.create({
  baseURL: apiConfig.ApiBaseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: apiConfig.Apitimeout, // request timeout
});

// service.defaults.headers.common['api_token'] = SESSION;
service.interceptors.request.use(
  config => {
    // $withoutToken 用于标识接口请求时不要带token
    const { $withoutToken, ...params } = config.params || {};
    if (!$withoutToken && ['/api/register', '/api/login'].indexOf(config.url) === -1) {
      const SESSION = localStorage.getItem(USER_SESSION_KEY);
      params.api_token = SESSION;
    }
    config.params = paramsNonEmptyFilter(params);
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    if(response.status==200) {
      const res = response.data;
      if (res.code !== 2) {
        const msg = res.msg || 'Error';
        if (msg === 'api_token不正确') {
          console.log(response.config.url);
          checkGoLogin({ api: response.config.url });
        } else {
          Message({ message: msg, type: 'error', duration: 5 * 1000 });
        }
        return Promise.reject(msg);
      }
      else if (response.status===401) {
        checkGoLogin({ api: response.config.url });
        return Promise.reject("请登录网站");
      }
      else {
        return res;
      }
    }else{
      console.log("数据访问失败")
    }
  },
  error => {
    if (error.response){
      if(error.response.status>=500){
        const msg = error.msg || 'Error';
        console.log('err' + msg); // for debug
        /*Message({
          message: msg,
          type: 'error',
          duration: 5 * 1000,
        });
        return Promise.reject(error);*/
      }else{
        console.log("error",error);
      }
    }


  }
);

export default service;



