<template>
  <div class="member-show">
    <article-header title="会员大咖秀" :more="false"></article-header>
    <div class="list">
      <div class="item" v-for="(item, index) of list" :key="index">
        <span class="name">
          <span class="number">「活跃度：{{ item.Cumulative}}」</span>
          <img class="level" :src="imgList[item.UserLevel]" alt />
          <!--<span>[{{userLevelList[item.UserLevel + 1]}}]</span>-->
          <span>{{ item.Uid}}</span>    
        </span>
        <!-- <span class="title">&lt;{{ item.UserLevel }}&gt;</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import ArticleHeader from '../common/ArtHeaderRight';
export default {
  name: 'MemberShow',
  components: { ArticleHeader },
  data() {
    return {
      list: [],
      userLevelList: ['MIMS医生', 'MIMS顾问', 'MIMS专家'], // 1,2,3
      imgList: [
        require('../../assets/image/MIMS1.png'),
        require('../../assets/image/MIMS2.png'),
        require('../../assets/image/MIMS3.png')
      ]
    };
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    this.$apis.app.getTopMemberLevel().then(res => {
      this.list = res.data;
    });
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
.member-show {
  .list {
    padding-top: 20px;
    .item {
      font-size: 14px;
      color: $font-main;
      padding: 8px 0;
      font-weight: bold;
      .svg-icon {
        width: 1.5em;
        height: 1.5em;
        color: $theme-red;
        position: relative;
        top: 4px;
        margin-right: 2px;
      }
      .level {
        width: 30px;
        position: relative;
        top: 6px;
        margin-right: 4px;
      }
      // &:first-child {
      //   position: relative;
      //   &:after {
      //     content: '';
      //     width: 52px;
      //     height: 42px;
      //     background: url('../../assets/image/icon-top.png') no-repeat center center;
      //     background-size: cover;
      //     position: absolute;
      //     right: 0;
      //     top: 6px;
      //   }
      //   .name {
      //     font-size: 20px;
      //     display: block;
      //     .number {
      //       color: $theme-red;
      //     }
      //   }
      //   .title {
      //     padding: 10px 0 0 54px;
      //     display: inline-block;
      //   }
      // }
      .name {
        padding-right: 16px;
        display: inline-block;
      }
      .title {
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
  .banner {
    margin: 16px 0;
    height: 65px;
    overflow: hidden;
    .img {
      width: 100%;
    }
  }
}
</style>
