import $http from '@/utils/request';
import $apis from './api';
import { USER_SESSION_KEY } from '@/constants';

export const sendcode = params => {
  return $http.get($apis.sendcode, { params });
};

export const getAvatars = params => {
  return $http.get($apis.avatar, { params }).then(res => {
    return res.data.sort((a, b) => a.Sex - b.Sex);
  });
};

export const editAddress = data => {
  return $http.get($apis.editAddress, { params: data });
};

export const getAddress = data => {
  return $http.get($apis.memberaddress, { params: data });
};

export const delAddress = data => {
  return $http.get($apis.delAddress, { params: data });
};

export const verifyCode = data => {
  return $http.get($apis.verifyCode, { params: data });
};

export const editPassword = data => {
  return $http.get($apis.editPassword, { params: data });
};

export const findPassword = data => {
  return $http.get($apis.findPassword, { params: data });
};

export const getRegion = () => {
  return $http.get('/region.json').then(res => {
    const map = {};
    const cityMap = {};
    res.data.forEach(item => {
      item.Id = item.Id + '';
      item.parentId = item.parentId + '';

      if (item.isLeaf) {
        cityMap[item.id] = item.type === 'province' ? ['' + item.id] : ['' + item.parentId, '' + item.id];
      }

      if (item.type === 'province') {
        map[item.id] = {
          ...item,
          children: [],
        };
      } else if (item.type === 'city') {
        map[item.parentId].children.push(item);
      }
    });
    const list = Object.keys(map).map(id => {
      const province = map[id];
      const item = { value: id, label: province.name };
      if (province.children.length) {
        item.children = province.children.map(i => ({ value: i.id, label: i.name }));
      }
      return item;
    });

    const result = { list, map: cityMap };
    return result;
  });
};

export const doRegister = data => {
  return $http.get($apis.register, { params: data });
};

export const editInfo = data => {
  return $http.get($apis.editInfo, { params: data });
};

export const getFav = params => {
  return $http.get($apis.favorite, { params });
};

export const setFav = params => {
  return $http.get($apis.setFav, { params });
};

export const getUserPoint = params => {
  return $http.get($apis.userPoint, { params });
};

export const getUserPointDetail = params => {
  return $http.get($apis.userPointDetail, { params });
};

export const getUserLevel = params => {
  return $http.get($apis.userLevel, { params });
};

export const getPointRules = params => {
  return $http.get($apis.pointRules, { params });
};

export const doLogin = data => {
  return $http.get($apis.login, { params: data });
};

export const doLogout = () => {
  return $http.get($apis.logout);
};

export const getUserInfo = () => {
  const SESSION = localStorage.getItem(USER_SESSION_KEY);

  if (!SESSION) return Promise.reject();
  return $http.get($apis.info).then(res => {
    return {
      data: { ...res.data, AttentionTags: res.data.AttentionTags.split(',') },
    };
  });
};
