<template>
  <div class="home-footer">
    <div class="query">
      <div class="container main-inner">
        <div class="title">药物查询A-Z</div>
        <div class="title-info">按药物英文名的字母顺序排列（商品名）</div>
        <div class="letters">
          <a
            class="letter"
            :href="`${$constants.routers.tradeName.link}?letter=${item}&category=Brand`"
            v-for="(item, index) of letters"
            :key="index"
            >{{ item }}</a
          >
        </div>
        <div class="title-info">按药物英文名的字母顺序排列（通用名）</div>
        <div class="letters">
          <a
            class="letter"
            :href="`${$constants.routers.tradeName.link}?letter=${item}&category=Generic`"
            v-for="(item, index) of letters"
            :key="index"
            >{{ item }}</a
          >
        </div>
        <el-row class="contact-us">
          <el-col :span="12">联系我们</el-col>
        </el-row>
        <el-row class="contact-icon">
          <el-col :span="3">
            <img class="img" src="../../../assets/image/right-nav02.png" alt="MIMS医药资讯"  style="width: 90px" />
            <p class="info pl1">MIMS医药资讯</p>
          </el-col>
          <el-col :span="3" class='hidden'>
            <img class="mr2 img" src="../../../assets/image/right-nav01.png" alt="MIMS公众号" style="width: 90px"/>
            <p class="info pl2">MIMS公众号</p>
          </el-col>
        </el-row>
        <div class="links">
          友情链接：
          <a
            v-for="(item, index) of app.externalLinks"
            :key="index"
            :href="item.Url"
            target="view_window"
          >{{item.Label}}</a>
        </div>
      </div>
    </div>
    <CommonFooter></CommonFooter>
  </div>
</template>

<script>
import CommonFooter from './Footer';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'HomeFooter',
  components: { CommonFooter },
  data() {
    return {
      links: {
        wechat: 'http://weixin.qq.com/r/s3Vudl-EfwIArRsV9yCh',
        weibo: 'https://www.weibo.com/mimschina',
        appStore: 'https://itunes.apple.com/cn/app/mims-%E4%B8%AD%E5%9B%BD/id665787264',
        androidStore: 'http://www.anzhi.com/pkg/d82d_cn.mimsconsult.mims.com.html',
      },
      letters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
    };
  },
  computed: {
    ...mapState({
      app: state => state.app,
    }),
  },
  created() {
    this.getExternalLinks()
      .then(() => {
      })
  },
  mounted() {

  },
  methods: {
    ...mapActions('app', ['getExternalLinks']),
  }
};
</script>

<style scoped lang="scss">
@import '../../../assets/css/variables';
@import '../../../assets/css/mixin';
.home-footer {
  color: #fff;
  .query {
    background: #1a1a1a;
    padding: 30px 0;
    .title {
      background: $theme-red;
      font-size: 20px;
      line-height: 35px;
      color: #fff;
      display: inline-block;
      padding: 0 10px;
      margin: 30px 0;
    }
    .title-info {
      font-size: 14px;
    }
    .letters {
      padding: 14px 0;
      display: flex;
      .letter {
        flex: 1;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-right: 6px;
        border: 1px solid #fff;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .contact-us {
      font-size: 20px;
      padding: 16px 0;
      border-bottom: 2px solid $theme-red;
    }
    .contact-icon {
      padding: 17px 0;
      a {
        color: #fff;
        .svg-icon {
          width: 34px;
          height: 34px;
          margin-right: 24px;
        }
      }
    }
    .links {
      font-size: 14px;
      padding: 17px 0;
      a {
        color: #999;
        line-height: 20px;
        display: inline-block;
        padding: 0 10px;
        border-right: 1px solid #999;
        &:last-child {
          border-right-width: 0;
        }
      }
    }
  }
}
</style>
