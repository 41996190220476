<template>
  <el-select
    :value="value"
    class="full-w"
    placeholder="请选择科室"
    size="small"
    @change="onChange"
    :disabled="disabled"
  >
    <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id"> </el-option>
  </el-select>
</template>
<script>
export default {
  name: '',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [Number, String] },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      options: [
        { id: 'ALLERGY AND IMMUNOLOGY', label: '临床免疫和变态反应科' },
        { id: 'ANAESTHESIOLOGY', label: '麻醉科' },
        { id: 'CARDIOLOGY', label: '心内科' },
        { id: 'DERMATOLOGY/VENEREOLOGY', label: '皮肤性病科' },
        { id: 'EMERGENCY MEDICINE', label: '急诊科' },
        { id: 'ENDOCRINOLOGY', label: '内分泌科' },
        { id: 'GASTROENTEROLOGY', label: '消化内科' },
        { id: 'GENERAL PRACTICE', label: '全科' },
        { id: 'GERIATRIC MEDICINE', label: '老年病科' },
        { id: 'HAEMATOLOGY', label: '血液科' },
        { id: 'HEPATOLOGY', label: '肝病科' },
        { id: 'INFECTIOUS DISEASES', label: '传染科' },
        { id: 'INTERNAL MEDICINE', label: '内科' },
        { id: 'NEPHROLOGY', label: '肾脏科' },
        { id: 'NEUROLOGY', label: '神经内科' },
        { id: 'OBSTETRICS &amp; GYNAECOLOGY', label: '妇产科' },
        { id: 'ONCOLOGY', label: '肿瘤科' },
        { id: 'OPHTHALMOLOGY', label: '眼科' },
        { id: 'ORTHOPAEDIC SURGERY', label: '骨外科' },
        { id: 'OTHERS', label: '其他' },
        { id: 'OTOLARYNGOLOGY (ENT)', label: '耳鼻喉科' },
        { id: 'PAEDIATRICS', label: '儿科' },
        { id: 'PALLIATIVE CARE', label: '姑息治疗科' },
        { id: 'PHYSICAL MEDICINE AND REHABILITATION', label: '物理治疗与康复医学科' },
        { id: 'PLASTIC SURGERY', label: '整形美容科' },
        { id: 'PSYCHIATRY/MENTAL HEALTH', label: '精神、心理卫生科' },
        { id: 'RADIOLOGY', label: '放射科' },
        { id: 'RESPIRATORY MEDICINE', label: '呼吸内科' },
        { id: 'RHEUMATOLOGY', label: '风湿病科' },
        { id: 'SURGERY', label: '外科' },
        { id: 'UROLOGY', label: '泌尿科' },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onChange(v) {
      this.$emit('change', v);
    },
  },
};
</script>
<style lang="scss" scoped></style>
