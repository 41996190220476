<template>
  <el-select :disabled="disabled" placeholder="请选择" :value="value" class="full-w" @change="onChange">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
  </el-select>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [Number, String] },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      options: [
        { value: '1', label: '住院医师' },
        { value: '2', label: '主治医师' },
        { value: '3', label: '副主任医师' },
        { value: '4', label: '主任医师' },
        { value: '-1', label: '其他' },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onChange(v) {
      this.$emit('change', v);
    },
  },
};
</script>
<style lang="scss" scoped></style>
