/**
 * Created by WL on 20/08/11.
 */

import * as $constants from '@/constants';
import moment from 'moment';

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @returns {string}
 */
export function formatTime(time) {
  if (!time) return '';
  const target = moment(time);
  const now = moment();

  const diff = now.diff(target) / 1000;
  let result = '';
  if (diff < 30) {
    result = '刚刚';
  } else if (diff < 3600) {
    result = Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    result = Math.ceil(diff / 3600) + '小时前';
  } else {
    result = target.format('YYYY-MM-DD');
  }
  return result;
}

// formatTime('2020-08-29 20:15:00');

/**
 * @param {string} url
 * @returns {Object}
 */
export function query2Obj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return search
    .split('&')
    .map(i => i.split('='))
    .reduce((map, item) => {
      map[item[0]] = decodeURIComponent(item[1]);
      return map;
    }, {});
}

export function obj2query(obj = {}) {
  return Object.keys(obj)
    .map(k => `${k}=${obj[k]}`)
    .join('&');
}

export const paramsNonEmptyFilter = params => {
  if (Object.prototype.toString.call(params) !== '[object Object]') {
    return params;
  }

  return Object.keys(params)
    .filter(k => params[k] !== undefined && params[k] !== '')
    .reduce((result, k) => {
      result[k] = params[k];
      return result;
    }, {});
};

export const go2login = _blank => {
  if (_blank) {
    window.open($constants.routers.login.link, 'login');
  } else {
    location.replace(`${$constants.routers.login.link}?from=${encodeURIComponent(location.href)}`);
  }
};

export const checkGoLogin = (config = {}) => {
  if (
    ['mine', 'integral', 'fav', 'address', 'newsDetail', 'hotDetail', 'videoDetail', 'tradeName', 'diseaseName', 'disease', 'drugInfo',  'search', 'shopConfirm']
      .map(i => $constants.routers[i].link)
      .some(i => i === location.pathname)
  ) {
    console.log('checked should go to login page');
    go2login();
  }

  const { api } = config;
  if (['/api/favorite_op'].some(i => i === api)) {
    go2login(true);
  }
};

export const checkLogin = () => {
  return new Promise((resolve, reject) => {
    if (!localStorage.getItem($constants.USER_SESSION_KEY)) {
      go2login(true);
      reject();
    } else {
      resolve();
    }
  });
};

export const pad = num => {
  return num < 10 ? '0' + +num : num;
};
