<template>
  <div class="latest-meeting">
    <ListHeader title="最新热点" icon="" :hasMore="hasMore">
      <div>
        <el-tag
          v-for="(item, idx) in timeOptions"
          size="mini"
          class="csp tag"
          :type="selectedTimeIdx === idx ? 'primary' : 'info'"
          :key="idx"
          @click="selectedTimeIdx = idx"
        >
          {{ item.label }}
        </el-tag>
      </div>
      <a :href="listUrl" v-if="hasMore" slot="more" class="csp ml1">更多</a>
    </ListHeader>
    <div v-loading="loading">
      <div class="list fs14" v-if="list.length">
        <div class="item" v-for="(item, index) of list" :key="index">
          <div class="content line1 csp" @click="go2detail(item)">{{ item.Title }}</div>
          <div class="time fs12">{{ $utils.formatTime(item.ReleaseDate) }}</div>
        </div>
      </div>
      <div class="no-data" v-else>暂无数据</div>
    </div>
    <a :href="listUrl" class="list-more db" v-if="hasMore">查看更多</a>
  </div>
</template>

<script>
import ListHeader from '../list-header';
import moment from 'moment';
export default {
  components: { ListHeader },
  props: {
    hasMore: { type: Boolean, default: true },
    params: { type: Object, default: () => ({}) },
  },
  data() {
    const end_date = moment().format('YYYY-MM-DD hh:mm');
    return {
      selectedTimeIdx: 0,
      timeOptions: [
        // {
        //   value: {
        //     start_date: moment()
        //       .subtract(24, 'hours')
        //       .format('YYYY-MM-DD hh:mm'),
        //     end_date,
        //   },
        //   label: '24小时',
        // },
        {
          value: {
            start_date: moment()
              .subtract(7, 'days')
              .format('YYYY-MM-DD hh:mm'),
            end_date,
          },
          label: '一周内',
        },
        {
          value: {
            start_date: moment()
              .subtract(1, 'months')
              .format('YYYY-MM-DD hh:mm'),
            end_date,
          },
          label: '一月内',
        },
      ],
      list: [],
      loading: true,
    };
  },
  computed: {
    listUrl() {
      // eslint-disable-next-line no-unused-vars
      const { limit, ...rest } = this.params;
      const query = this.$utils.obj2query(rest);
      return `${this.$constants.routers.news.link}?${query}`;
    },
  },
  watch: {
    selectedTimeIdx: {
      immediate: true,
      handler() {
        this.getList();
      },
    },
  },
  methods: {
    getList() {
      this.loading = true;
      this.$apis.news
        .getNews({
          limit: 8,
          ...this.params,
          ...this.timeOptions[this.selectedTimeIdx].value,
        })
        .then(res => {
          this.list = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    go2detail(item) {
      location.assign(`${this.$constants.routers.hotDetail.link}?type=news&id=${item.Id}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
@import '../../assets/css/mixin.scss';
.tag {
  margin: 0 1px;
}
.list-more {
  margin-top: 20px;
  background: #e4e4e4;
  color: $theme-color;
  height: 28px;
  line-height: 28px;
  text-align: center;
}
.latest-meeting {
  .header {
    display: flex;
    font-size: 22px;
    border-bottom: 1px solid #ccc;
    .title {
      padding: 6px 0;
      border-bottom: 4px solid $theme-color;
    }
    .more {
      flex: 1;
      text-align: right;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .list {
    padding: 20px 0;
    .item {
      padding: 10px 0 0 20px;
      position: relative;
      border-left: 1px solid #ccc;
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        border: 2px solid $theme-color;
        box-sizing: border-box;
        position: absolute;
        left: -5px;
        top: 16px;
      }
      .content {
        line-height: 22px;
        color: $font-main;
      }
      .time {
        color: $font-sec;
        line-height: 2;
      }
    }
  }
}
</style>
