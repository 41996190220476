<template>
	<div>
		<Top />
		<div v-for="(type, idx) in goods" :key="idx" class="mt4 mb8">
			<div class="header mb4 pb2">
				<div flex="main:justify">
					<div flex="cross:center">
						<img :src="type.icon" width="18" class="vm" />
						<span class="fs16 fwb color-primary ml1">{{ type.title }}</span>
					</div>
					<div flex="cross:center">
						<span class="more csp ml2" v-on:click="$emit('click')"></span>
					</div>
				</div>
			</div>
			<div class="list" flex>
				<template v-for="(item, index) in type.list">
					<ImgtextItem :key="index" :tip="type.tip" v-if="index<5" :item="item" />
				</template>
			</div>
			<div class="list" style="margin-top: 60px" flex v-if="type.list.length>5">
				<template v-for="(item, index) in type.list">
					<ImgtextItem :key="index" :tip="type.tip" v-if="index>4&&index<10" :item="item" />
				</template>
			</div>
			<div class="list" style="margin-top: 60px" flex v-if="type.list.length>10">
				<template v-for="(item, index) in type.list">
					<ImgtextItem :key="index" :tip="type.tip" v-if="index>9" :item="item" />
				</template>
			</div>
			<div class="list" style="margin-top: 60px" flex v-if="type.list.length>15">
				<template v-for="(item, index) in type.list">
					<ImgtextItem :key="index" :tip="type.tip" v-if="index>14" :item="item" />
				</template>
			</div>

		</div>
	</div>
</template>

<script>
	import Top from './top';
	import ImgtextItem from '@/components/imgtext-item';

	export default {
		components: {
			Top,
			ImgtextItem,
		},
		data() {
			return {
				goods: [{
						icon: "",
						title: '礼品&书籍',
						list: [],
						tip: '礼品&书籍',
					},

				],
			};
		},
		created() {
			this.$apis.shop.getProducts({
				Catetory: 1,
				limit: 100
			}).then(res => {
				this.goods[0].list = res.data;
			});
			this.$apis.shop.getProducts({
				Catetory: 2,
				limit: 100
			}).then(res => {
				this.goods[1].list = res.data;
			});
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.header {
		border-bottom: 1px solid #d7dae2;
	}

	.more {
		width: 15px;
		height: 12px;
		display: inline-block;
		/*position: absolute;*/
		/*top: 15px;*/
		/*right: 0;*/
		background: url('../../assets/image/icon-more.png') no-repeat center left;
		background-size: cover;
	}
</style>
