export const SexMap = { 0: '男', 1: '女' };
export const userInfoMap = {
  Uid: { label: '账户名' },
  Name: { label: '真实姓名' },
  PhoneNumber: { label: '手机号' },
  Sex: { label: '性别', type: 'radio', getValue: val => SexMap[val] },
  City: { label: '城市', type: 'city' },
  profession: { label: '职业'},
  Email: { label: '邮箱' },
  Hospital: { label: '医院名称', cih: true }, // cih(careerInHospital)
  Department: { label: '科室', cih: true },
  PositionTitle: { label: '职称', cih: true },
  HospitalAddr: { label: '医院地址', cih: true },
  DoctorNo: { label: '职业编号', cih: true },
  AttentionTags: { label: '关注的治疗领域' },
  Wechat: { label: '微信号' },
};

export const routers = {
  index: { link: '/index.html', label: '首页' },
  mine: { link: '/user.html', label: '个人中心' },
  integral: { link: '/userIntegral.html', label: '积分信息' },
  document: { link: '/document.html', label: '文档资源' },
  fav: { link: '/userCollection.html', label: '我的收藏' },
  address: { link: '/userAddress.html', label: '默认地址' },
  register: { link: '/register.html', label: '注册' },
  login: { link: '/login.html', label: '登录' },
  search: { link: '/search.html', label: '搜索' },
  shopHome: { link: '/shopMall.html', label: '商城' },
  shopDetail: { link: '/shopDetails.html', label: '商品详情' },
  shopConfirm: { link: '/shopConfirm.html', label: '商品确认' },
  tradeName: { link: '/tradeName.html', label: '药品信息' },
  drugClass: { link: '/drugClass.html', label: '药品分类' },
  drugInfo: { link: '/drugInfo.html', label: '药品信息' },
  info: { link: '/info.html', label: '药品信息' },
  drugRelated: { link: '/drugRelated.html', label: '药物相关信息' },
  product: { link: '/product.html', label: '产品信息' },
  pharmacist: { link: '/pharmacist.html', label: '药商信息' },
  typicalCase: { link: '/typicalCase.html', label: '侃侃病例' }, // 首页-侃侃病例主页
  typicalCases: { link: '/typicalCases.html', label: '药商信息' }, // 药商信息-侃侃病例
  changepw: { link: '/changepw.html', label: '修改密码' },
  authority: { link: '/authority.html', label: '权威原版' },
  disease: { link: '/disease.html', label: '疾病指南' },
  diseaseName: { link: '/diseaseName.html', label: '疾病指南' },
  relatedDisease: { link: '/relatedDisease.html', label: '相关病症' },
  news: { link: '/news.html', label: '新闻资讯' },
  newsDetail: { link: '/newsDetail.html', label: '详情' },
  pdfDetail: { link: '/pdfDetail.html', label: '详情' },
  hotDetail: { link: '/hotDetail.html', label: '详情' }, // 热点无需登录验证
  meeting: { link: '/meeting.html', label: '会议' },
  train: { link: '/train.html', label: '培训课堂' },
  clinical: { link: '/clinical.html', label: '临床学院' },
  club: { link: '/club.html', label: '美迪会' },
  specialty: { link: '/specialty.html', label: '科室' },
  specialtyHot: { link: '/specialtyHot.html', label: '新冠热点' },
  videoDetail: { link: '/videoDetail.html', label: '视频详情' },
  newProdFocus: { link: '/newProdFocus.html', label: '新产品聚焦' },
  companyProfile: { link: '/companyProfile.html', label: '公司简介' },
  terms: { link: '/terms.html', label: '服务条款' },
  privacy: { link: '/privacy.html', label: '隐私声明' },
  securityAdvisory: { link: '/securityAdvisory.html', label: '安全通告' },
};

export const USER_SESSION_KEY = 'MIMS_SESSSION';
export const NIHAO = 'MIMS_DHISD';

export const resTypeMap = {
  1: 'meeting',
  2: 'news',
  3: 'train',
  4: 'disease',
};
