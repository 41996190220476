<template>
  <div flex="main:justify" class="list ph2 pv2 oa">
    <div v-for="item in letters" :key="item" class="item tc csp mb2 fwb" @click="go2search(item)">{{ item }}</div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    category: { type: String },
    link: { type: String },
  },
  data() {
    return {
      letters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    go2search(item) {
      location.assign(`${this.link || ''}?letter=${item}&category=${this.category}`);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/css/variables.scss';

.list {
  width: 78px;
  max-height: 60vh;
  flex-wrap: wrap;
  .item {
    padding: 0;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border: 1px solid $theme-color;
    color: $theme-color;
  }
}
</style>
