const apis = {
  previewInformation: '/api/meeting_detail',
  login: '/api/login',
  logout: '/api/logout',
  register: '/api/register',
  sendcode: '/api/sendcode',
  special: '/api/special',
  treatmentTags: '/api/specialty_treatment',
  specialtyDisease: '/api/specialty_disease',
  tagcloud: '/api/tagcloud',
  info: '/api/info',
  editInfo: '/api/info_edit',
  alphabet: '/api/alphabet_search',
  news: '/api/information_new',
  newsDetail: '/api/information_detail',
  // 培训课堂
  traincourse: '/api/traincourse_new',
  // 培训课堂详情
  traincourseDetail: '/api/traincourse_detail',
  trainCourseHot: '/api/traincours_hot',
  // meeting: '/api/meeting',
  meeting: '/api/meeting_new',
  hotmeeting:'/api/meeting_hot',
  searchassist: '/api/search_assist',
  informationSearchKey: '/api/information_search_key',
  meetingDetail: '/api/meeting_detail',
  diseasecases: '/api/diseasecases_new',
  diseaseDetail: '/api/diseasecases_detail',
  diseaseList: '/api/disease_list',
  drugClasslist: '/api/classlist',
  drugList: '/api/resource_list', // 药品搜索列表
  searchDrug: '/api/searchDrug', // 药品搜索列表
  companyInfo: '/api/companyinfo', // 药商公司信息
  druggistList: '/api/company_list', // 药商搜索列表
  drugDetail: '/api/monograph_information',
  drugIcon: '/api/drug_icon', // 药品详细-图标
  advert: '/api/focus',
  authorityAd: '/api/authorityad', // 权威版权广告
  newFocusList: '/api/new_focus_list',
  mimsfocus: '/api/mimsfocus',
  shopEntry: '/api/point_get',
  preferredlinks: '/api/preferredlinks',
  banner: '/api/banner',
  notice: '/api/notice',
  college: '/api/college',
  getCollegeDetail: '/api/college_detail',
  getToken: '/api/get_token',
  // 美迪会
  meidihui: '/api/meidihui',
  meidihuiDetail: '/api/meidihui_detail',
  // 会员大咖秀
  topMemberLevel: '/api/Topmemberlevel',
  // 添加/编辑用户地址（目前用户地址就一个，没有就增加，有就修改）
  editAddress: '/api/address_edit',
  memberaddress: '/api/memberaddress',
  delAddress: '/api/address_del',
  // 商品接口（图书、票）
  product: '/api/product',
  // 商品详情
  productDetail: '/api/product_detail',
  createOrder: '/api/order_create',
  avatar: '/api/avatar',
  academ: '/api/academ', // 权威原版
  academ_detail: '/api/academ_detail',
  favorite: '/api/favorite', // 我的收藏
  setFav: '/api/favorite_op', // 我的收藏
  userPoint: '/api/user_point', // 积分
  userPointDetail: '/api/user_point_detail', // 积分明细
  userLevel: '/api/user_level', // 积分等级分值
  pointRules: '/api/point_rules', // 积分规则
  verifyCode: '/api/verify_code', // 验证验证码
  editPassword: '/api/password_edit', // 修改密码
  findPassword: '/api/password_find', // 找回密码
  externalLink: '/api/out_link', // 通用底部-外部链接
  searchDrugInformations:'/api/searchDrugInformations', // 查询药品相关资讯
  searchDrugDiseasecases:'/api/searchDrugDiseasecases',
  checkAuth: '/api/checkresAuth', // 检查用户是否认证过资源访问权限
  exchangeRes:'/api/exchangeRes',// 兑换资源
  searchDrugImge:'/api/searchDrugImge',//形狀搜索
  searchDrugColor:'/api/searchDrugColor',//形狀搜索
  chui:'/api/Diytouch',//形狀搜索
};

export default Object.keys(apis).reduce((map, api) => {
  const host = process.env.NODE_ENV !== 'production' ? '' : '//web.mims.com.cn';
  map[api] = host + apis[api];
  return map;
}, {});
