<template>
  <div class="item mt5">
    <template v-if="item.cfrom === 'Brand'">
      <div class="title mb1">
        <span class="name mr2 fs15 fwb csp" @click="go2detail(item)">
          <template v-if="item.cfrom ==='Brand'">
            <span  v-html='item.BrandName + " " +item.BrandNameEn'></span>
          </template>
          <span v-if="item.cfrom ==='Generic'">{{item.GenericName}} {{item.GenericNameEn}}</span>
          {{item.name}}
        </span>
        <span v-if="item.package && item.package.ePIC&& item.package.ePIC=='Y' && item.company" @click="go2company(item)">
          [<span class="name fwb csp">{{item.company.Name}} {{item.company.EnName}}</span>]
        </span>
        <span class="name" v-html="item.Name"></span>
      </div>
      <p class="indication fwb mb1" v-if="item.Indication">
        <span>适应症：</span>
        <span v-html="item.Indication"></span>
      </p>
      <p class="classify">
        <span class="fwb"  >MIMS药理分类：</span>
        <a class="color-link csp" :href="$constants.routers.search.link+'?keywords='+item.ClassDescription+'&searchType=1&searchBy=Class'" v-for="(clasDesc,idx) in item.ClassItems" :key="idx">
          {{clasDesc.ClassDescription}} {{clasDesc.ClassDescription_en}}
        </a>
		
		<span style="color:#000000">{{item.ClassDescription}}</span>
      </p>
      <a
        v-if="item.package.eCPI==='Y'"
        :href="$constants.routers.pharmacist.link+'?safeUrl='+item.safeUrl+' &cfrom='+item.cfrom">
        <img class="pv1" src="../assets/image/epic.jpg" style="width: 16px;"/>
      </a>

    </template>
    <template v-else>
      <div class="title mb1">
        <span class="name mr2 fs15 fwb csp" @click="go2detail(item)">{{item.GenericName}}  {{item.safeUrl}}</span>
        <span class="comp">{{item.comp}}</span>
      </div>
      <p class="classify">
        <span class="fwb">MIMS药理分类：</span>
        <a class="color-link csp" :href="$constants.routers.search.link+'?keywords='+item.ClassDescription+'&searchType=1&searchBy=Class'">
        {{item.ClassDescription}}
        </a>
      </p>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'DrugItem',
    props: {
      item: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        // item: {
        //   name: '诺欣妥1',
        //   english: 'Entresto',
        //   manufacture: '丹尼制药 Danipharm',
        //   type: '乳果糖 Lactulose',
        //   comp: '氢氧化铝 + 颠茄 + 三硅酸镁 + 维生素U aluminium hydroxid',
        //   indication: '缓解胃酸过多引起的胃痛、胃烧灼感及慢性胃炎。',
        //   classify: '缓泻剂和泻药 Laxatives, Purgatives',
        // }
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      go2detail(item) {
        location.href = `${this.$constants.routers.drugInfo.link}?safeUrl=${encodeURIComponent(item.safeUrl)}&cfrom=${
          item.cfrom
          }`;
      },
      go2company(item){
        location.href = `${this.$constants.routers.pharmacist.link}?safeUrl=${encodeURIComponent(item.safeUrl)}&cfrom=${item.cfrom}&fullname=${item.company.FullName}`;
      }
    },
  }
</script>

<style scoped lang="scss">
.item {
  .title {
    .name {
      color: #0099FF;
    }
    .english {
      color: #23A2FF;
    }
    .company {
      color: #000;
    }
    .new {
      border: 1px solid $theme-red;
      color: $theme-red;
    }
    .comp {
      color: #888;
    }
  }
  .indication {
    color: #000;
    span {
    }
  }
  .classify {
    span {
      color: #000;
    }
    color: #23A2FF;
  }
}
</style>
