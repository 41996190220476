import clonedeep from 'lodash.clonedeep';
import isplainobject from 'lodash.isplainobject';

export const setState = function(state, { payload }) {
  if (Array.isArray(payload)) {
    payload.forEach(item => {
      const { k, v } = item;
      state[k] = v;
    });
  } else {
    const { k, v } = payload;
    state[k] = v;
  }
};

export const setCache = function(options) {
  const { commit, key, cache, deep } = options;
  setTimeout(() => {
    let v = cache;
    if (Array.isArray(cache)) {
      v = deep ? clonedeep(cache) : [...cache];
    }
    if (isplainobject(cache)) {
      v = deep ? clonedeep(cache) : { ...cache };
    }

    commit('setState', {
      payload: { k: key, v },
    });
  }, 0);
};
