<template>
  <div class="headerNav">
    <!--通用没有品牌信息-->
    <template v-if="isGeneric">
      <div class="headerNav-center mt2 fs15 pv2">
        <div class="center-l">
          <span class="fwb ml3">{{ detail.Brife && detail.Brife.MonographName }}</span>
        </div>
        <div class="center-r fs15">{{ (detail.mainCompany || {}).fullname }}</div>
      </div>
      <!--<div flex="cross:center" class="pv3 line-b">-->
        <!--<div class="csp mr8" v-for="(item, idx) in menuFilter" :key="idx" :class="{ active: checkCurrent(item) }">-->
          <!--<a :href="item.link + query" class="fs14" flex="cross:center" :class="{ linkActive: pathname === item.link }">-->
            <!--<svg-icon class="mh2" :icon-class="item.icon"></svg-icon>-->
            <!--<span>{{ item.label }}1</span>-->
          <!--</a>-->
        <!--</div>-->
      <!--</div>-->
    </template>
    <template v-else>
      <div v-if="detail.isBrand && detail.Package.eBrandLogo==='Y'" class="logo-wrap mb2" flex="main:justify cross:center">
        <div><img class="logo" v-if="detail.Brandlogo" :src="detail.Brandlogo" alt="Brandlogo" /></div>
        <div><img class="logo" v-if="detail.Companylogo" :src="detail.Companylogo" alt="Companylogo" /></div>
      </div>
      <div class="headerNav-center mt2 fs15 pv2">
        <div class="center-l">
          <span class="fwb ml3" v-html='detail.Brife && detail.Brife.MonographName'></span>
          <span v-if="detail.isBrand && detail.Package.eFull==='Y'" class="sym ml1 tbc-white tf3">M</span>
          <span v-for="(item, idx) of icon" :key="idx" class="sym ml1 tbc-white tf3">{{iconText[item]}}</span>
          <a
            v-if="detail.isBrand && detail.Package.eCPI==='Y'"
            :href="$constants.routers.pharmacist.link+query">
            <img class="i-cpi pl1" src="../../assets/image/iCPI.png" style="width: 19px;"/>
          </a>
          <a v-if="pdf" :href="pdfUrl">
            <img class="i-cpi pl1" src="../../assets/image/iGuideline_Mse.png" style="width: 19px;">
          </a>
          <!--<span class="sym ml1 tbc-white tf3">创新产品</span>-->
        </div>
        <div class="center-r fs15">{{ (detail.mainCompany || {}).fullname }}</div>
      </div>
      <div flex="cross:center" class="pv3 line-b">
        <div class="csp mr8" v-for="(item, idx) in menuFilter" :key="idx" :class="{ active: checkCurrent(item) }">
          <a :href="item.link + query" class="fs14" flex="cross:center" :class="{ linkActive: pathname === item.link }">
            <svg-icon class="mh2" :icon-class="item.icon"></svg-icon>
            <span>{{ item.label }}</span>
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'headerNav',
  props: {
    detail: { type: Object, default: () => ({}) },
  },
  data() {
    const { $constants } = this;
    const { safeUrl, cfrom } = this.$utils.query2Obj(location.search);
    const query = `?safeUrl=${safeUrl}&cfrom=${cfrom}`;
    const isBrand = cfrom==='Brand'
    return {
      cfrom,
      safeUrl,
      query: query,
      isBrand,
      menu: [
        {
          label: '药品信息',
          icon: 'drug',
          link: `${$constants.routers.drugInfo.link}`, // 为了样式，query不加上link
          activeIcon: require('@/assets/image/three2.png'),
          show: true
        },
        {
          label: '药商简介',
          icon: 'case',
          link: `${$constants.routers.pharmacist.link}`,
          activeIcon: require('@/assets/image/three2.png'),
          show: false
        },
        {
          label: '侃侃病例',
          icon: 'case',
          link: `${$constants.routers.typicalCases.link}`,
          activeIcon: require('@/assets/image/icon-case.png'),
          show: true
        },
        {
          label: '相关资讯',
          icon: 'news',
          link: `${$constants.routers.drugRelated.link}`,
          activeIcon: require('@/assets/image/three2.png'),
          show: true
        },
        {
          label: '产品资料',
          icon: 'msg',
          link: `${$constants.routers.product.link}`,
          activeIcon: require('@/assets/image/three2.png'),
          show: true
        },
      ],
      icon: [],
      pdf: '',
      iconText: {
        B: '保',
        J: '基',
        Y: '运',
        G: '精',
        M: '麻'
      }
    };
  },
  computed: {
    pdfUrl() {
      // this.pdf = 'https://www.mims.com.cn/china/contentmarketing/viewattachmentforallbrand/99e07821-1bde-483c-a519-a123015e24e6'
      return this.pdf?`${this.$constants.routers.document.link}?url=${encodeURIComponent(this.pdf)}` : '';
    },
    isGeneric () {
      return this.cfrom === 'Generic'
    },
    pathname() {
      return location.pathname;
    },
    menuFilter () {
      let _this = this
      return this.menu.filter(item => {
        return item.show || (!item.show &&_this.detail.Package && _this.detail.Package.eCPI === 'Y')
      })
    }
  },
  mounted () {
    const {safeUrl} = this
    this.$apis.drug.getDrugIcon({safeUrl}).then(res => {
      const {pdf, icon} = res
      this.pdf = pdf
      this.icon = icon
      console.log('res', res, icon, pdf);
      this.loading = false
    });
  },
  methods: {
    checkCurrent(item) {
      return item.link === location.pathname;
    },
  },
};
</script>

<style lang="scss" scoped>
.linkActive {
  color: $theme-color;
  font-weight: bold;
}
.active {
  color: $color-primary;
}
.headerNav {
  .logo-wrap {
    height: 60px;
    .logo {
      max-width: 200px;
      max-height: 60px;
      /*height: 60px;*/
    }
  }
  .headerNav-center {
    background: #e50011;
    color: #fff;
    overflow: hidden;
    .center-l {
      float: left;
      .i-cpi {
        position: relative;
        top: 3px;
      }
    }
    .center-r {
      float: right;
      margin-right: 15px;
    }
  }
}
</style>
