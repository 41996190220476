<template>
  <div class="medicine-recommend">
    <article-header title="药品推荐" :more="false"></article-header>
    <div class="list csp">
      <div class="item mb3" v-for="(item, index) of list" :key="'medicine-recommend' + index" @click="go2drug(item)">
        <div class="pic mr2">
          <img :src="item.Brandlogo" class="img img-contain full" />
        </div>
        <div class="info line2" v-html="item.Indication"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleHeader from '../common/ArtHeaderRight';
export default {
  name: 'MedicineRecommend',
  components: { ArticleHeader },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$apis.drug.getDrugRecommend().then(res => {
      this.list = res.data;
    });
  },
  methods: {
    go2drug(item) {
      location.href = `${this.$constants.routers.drugInfo.link}?safeUrl=${encodeURIComponent(item.BrandNameSafeUrl)}&cfrom=Brand`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
.medicine-recommend {
  .list {
    margin-top: 30px;
    .item {
      display: flex;
      margin-bottom: 15px;
      .pic {
        width: 117px;
        height: 36px;
        overflow: hidden;
        .img {
          width: 100%;
        }
      }
      .info {
        flex: 1;
        height: 38px;
        overflow: hidden;
        color: $font-main;
        font-size: 12px;
        line-height: 19px;
      }
    }
  }
}
</style>
