import axios from 'axios';
import { Message } from 'element-ui';
import apiConfig from '../config/mims.config';
const previewService =axios.create({
  baseURL:apiConfig.preApiBaseUrl,
  timeout:apiConfig.Apitimeout,
  responseType:"json",
  headers:{
    "content-type":"application/json"
  }
});

previewService.interceptors.response.use(
  response => {
    const res = response.data;
    const msg = res.msg || 'Error';
    if (res.code==200){
      return res;
    }else{
      Message({ message: msg, type: 'error', duration: 5 * 1000 });
      return Promise.reject(msg);
    }
  },
  error => {
    return Promise.reject(error.response)
  }
)

export default previewService;
