<template>
  <div class="top" flex="cross:center" v-if="list.length">
    <div class="top-l">
      <h4>商城</h4>
      <p>公告</p>
    </div>
    <div class="top-c"></div>
    <div class="top-r" flex>
      <a :href="item.NotifyUrl" target="_blank" class="single-line ph2" v-for="item in list" :key="item.Id">
        <span class="mr1 tag">热报</span>
        <span>{{ item.Title }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$apis.app.getNotice().then(res => {
      this.list = res.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.top {
  margin-top: 40px;
  font: 14px '';
  height: 36px;
  .top-l {
    h4 {
      color: #333;
      padding-left: 3px;
    }
    p {
      background: url('../../assets/image/shop-notice.png') no-repeat;
      color: #fff;
      width: 40px;
      padding-left: 3px;
    }
  }
  .top-c {
    height: 100%;
    width: 2px;
    background: red;
    margin-left: 23px;
  }
  .top-r {
    .tag {
      color: #ec1c24;
      border: 1px solid red;
      padding: 0 14px;
    }
    span {
      color: #333;
    }
  }
}
</style>
