<template>
  <el-select
    class="full-w"
    :value="value"
    placeholder="请选择关注的治疗领域"
    size="small"
    multiple
    :disabled="disabled"
    @change="onChange"
  >
    <el-option v-for="item in tagOptions" :key="item.Id" :label="item.Name" :value="item.Id"> </el-option>
  </el-select>
</template>
<script>
export default {
  name: '',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: Array },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      tagOptions: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.$apis.drug.getTreatmentTags().then(res => {
      this.tagOptions = res.data;
    });
  },
  mounted() {},
  methods: {
    onChange(v) {
      this.$emit('change', v);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-select {
  ::v-deep {
    .el-input {
      max-width: 96% !important;
    }
  }
}
</style>
