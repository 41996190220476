<template>
  <div class="prodFocus">
    <HeaderRight title="产品聚焦" :more="false" />
    <div class="list mv4" v-loading="loading">
      <span class="item csp" :style="item.style" v-for="(item, index) in list" :key="index" @click="go2detail(item)">
        {{ item.BrandName}}
      </span>
    </div>
  </div>
</template>

<script>
import HeaderRight from './ArtHeaderRight';
export default {
  components: {
    HeaderRight,
  },
  data() {
    return {
      list: [],
      loading: true,
    };
  },
  created() {
    this.$apis.app
      .getTagcloud()
      .then(res => {
        this.list = res.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    go2detail(item) {
      location.href = `${this.$constants.routers.drugInfo.link}?safeUrl=${encodeURIComponent(item.BrandNameSafeUrl)}&cfrom=Brand`;
    },
  },
};
</script>

<style lang="scss" scoped>
.prodFocus {
  .list {
    color: #333;
    font-size: 14px;
    height: 60px;
    overflow: hidden;
    .item {
      margin-right: 4px;
    }
  }
}
</style>
