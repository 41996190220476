<template>
  <div class="left-nav">
    <div
      class="item"
      :class="{ active: pathname === item.link, hover: item.hover }"
      v-for="(item, index) of list"
      :key="'nav' + index"
      @mouseover="selectStyle(item)"
      @mouseout="outStyle(item)"
    >
      <a :href="item.link">
        <span class="info">{{ item.name }}</span>
      </a>
      <span
        class="icon"
        :class="item.icon"
        @hover="handleHover(index)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftNav',
  props: {
    infoStyle: {type: Object, default: ()=>{}}
  },
  data() {
    return {
      activeIndex: 1,
      list: [
        { name: '返回首页', icon: 'icon-home', link: this.$constants.routers.index.link},
        { name: '会议资讯', icon: 'icon-meeting', link: this.$constants.routers.meeting.link },
        { name: '侃侃病例', icon: 'icon-case', link: this.$constants.routers.typicalCase.link },
        { name: '热点资讯', icon: 'icon-news', link: this.$constants.routers.news.link },
        { name: '培训课堂', icon: 'icon-class', link: this.$constants.routers.train.link },
      ],
    };
  },
  computed: {
    pathname() {
      return location.pathname;
    },
  },
  methods: {
    handleHover() {},
    selectStyle(item) {
      let _this = this;
      this.$nextTick(function() {
        this.list.forEach(function(item) {
          _this.$set(item, 'hover', false);
        });
        this.$set(item, 'hover', true);
      });
    },
    outStyle(item) {
      this.$set(item, 'hover', false);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
@import '../../assets/css/mixin.scss';
.left-nav {
  position: fixed;
  left: 0px;
  top: 240px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  .item {
    margin-bottom: 5px;
    box-sizing: border-box;
    background: #898989;
    font-size: 16px;
    color: #fff;
    display: flex;
    width: 40px;
    height: 40px;
    transition: 0.3s;
    text-align: right;
    position: relative;
    &.active {
      background: #e14c55;
    }
    &.hover {
      .info {
        opacity: 1;
        left: 40px;
      }
    }
    .icon {
      width: 40px;
      height: 40px;
      display: inline-block;
      &.icon-home {
        background: url('../../assets/image/icon-home-normal.png') no-repeat center;
        background-size: 20px 20px;
      }
      &.icon-meeting {
        background: url('../../assets/image/icon-meeting-normal.png') no-repeat center;
        background-size: 20px 20px;
      }
      &.icon-case {
        background: url('../../assets/image/icon-case-normal.png') no-repeat center;
        background-size: 20px 20px;
      }
      &.icon-news {
        background: url('../../assets/image/icon-news-normal.png') no-repeat center;
        background-size: 20px 20px;
      }
      &.icon-class {
        background: url('../../assets/image/icon-class-normal.png') no-repeat center;
        background-size: 20px 20px;
      }
    }
    .info {
      width: 100px;
      line-height: 40px;
      display: inline-block;
      background: $theme-red;
      color: #fff;
      text-align: center;
      overflow: hidden;
      position: absolute;
      left: -100px;
      transition: 0.3s;
      opacity: 0;
      z-index: 998;
    }
  }
}

@media screen and (min-width: 1441px) {
  .left-nav {
    left: calc((100% - 1440px)/2);
  }
}
@media screen and (min-width: 1600px) {
  .left-nav {
    .info {
      left: -100px!important;
    }
  }
}

</style>
