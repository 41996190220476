<template>
  <div class="d1 oh avatar-wrap">
    <img :src="value" v-if="value" class="avatar img-cover" />
    <el-popover placement="bottom" width="220" trigger="click">
      <div flex class="avatar-list">
        <img
          :src="item.ImagePath"
          v-for="(item, idx) in list"
          :key="idx"
          alt=""
          width="54"
          height="54"
          class="default-avatar mh1 mv1 img-cover csp"
          @click="changeAvatar(item.ImagePath)"
        />
      </div>
      <div class="replace csp pv1" slot="reference">更换</div>
    </el-popover>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: '' },
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.$apis.user.getAvatars().then(res => {
      this.list = res;
    });
  },
  mounted() {},
  methods: {
    changeAvatar(item) {
      this.$emit('change', item);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-wrap {
  border: 1px solid #ddd;
}
.avatar {
  background: #eee;
}
.avatar-list {
  width: 192px;
  flex-wrap: wrap;
}
.default-avatar {
  border-radius: 50%;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
.d1 {
  height: 105px;
  width: 105px;
  margin: 30px auto 0;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
  }
  .replace {
    color: #fff;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    background: $color-primary;
  }
  position: relative;
}
</style>
