import $apis from '@/apis';

export default {
  namespaced: true,
  state: {
    adMap: {},
  },
  getters: {},
  mutations: {
    setAdMap(state, payload) {
      state.adMap = payload;
    },
  },
  actions: {
    getAds({ commit }, payload) {
      $apis.ad.getAds(payload).then(res => {
        // const { data } = res;
        // Object.keys(data).forEach(page => {
        //   const groups = data[page];
        //   Object.keys(groups).forEach(groupId=>{
        //     groups[groupId].reduce((map, item) => {
        //       if (!map[item.AreaId]) {
        //         map[item.AreaId] = [];
        //       }
        //       map[item.AreaId].push(item);
        //       return map;
        //     }, {});
        //   });
        // });
        // const map = res.data.reduce((map, item) => {
        //   if (!map[item.AreaId]) {
        //     map[item.AreaId] = [];
        //   }
        //   map[item.AreaId].push(item);
        //   return map;
        // }, {});

        commit('setAdMap', res.data);
      });
    },
  },
};
