<template>
  <ArticleModule
    icon="icon-news"
    title="热点资讯"
    :href="listUrl"
    :showPagination="showPagination"
    :showMore="showMore"
    :total="total"
    v-loading="loading"
    @current-change="onPageChange"
  >
    <template v-if="newsList.length">
      <NewsItem v-for="(item, idx) in newsList" :key="idx" :item="item" />
    </template>
    <div class="no-data" v-else>暂无数据</div>
  </ArticleModule>
</template>
<script>
import ArticleModule from './article/Index';
import NewsItem from '@/components/NewsItem';

export default {
  components: { ArticleModule, NewsItem },
  props: {
    params: { type: Object, default: () => ({}) },
    showPagination: { type: Boolean, default: false },
    showMore: { type: Boolean, default: true },
    list: { type: Array, default: () => [] },
    useList: { type: Boolean, default: false },
  },
  data() {
    return {
      newsList: [],
      loading: true,
      total: 1,
      page: 1,
    };
  },
  computed: {
    listUrl() {
      // eslint-disable-next-line no-unused-vars
      const { limit, ...rest } = this.params;
      const query = this.$utils.obj2query(rest);
      return `${this.$constants.routers.news.link}?${query}`;
    },
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          this.newsList = nv;
        }
      },
    },
  },
  created() {
    if (!this.useList) {
      this.getList();
    } else {
      this.loading = false;
    }
  },
  mounted() {},
  methods: {
    onPageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      this.$apis.news
        .getNews({ limit: 3, page: this.page, ...this.params })
        .then(res => {
          this.newsList = res.data;
          this.total = res.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
