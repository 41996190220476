<template>
  <div class="training-class">
    <module-header title="培训课堂" icon="icon-class" :showMore="showMore" @click="go2trainingClass"></module-header>

    <div v-loading="loading">
      <template v-if="list.length">
        <div class="list" flex="">
          <div class="item" :style="style" flex-box="0" v-for="(item, index) of list" :key="index">
            <div class="pic csp" @click="go2detail(item)">
              <img :src="item.Cover" alt="" class="img-cover img-placeholder" />
            </div>
            <div class="content mt2">
              <div class="title mv2 line2 fs15 csp" @click="go2detail(item)">{{ item.Title }}</div>
              <div class="info line2 fs12 csp" @click="go2detail(item)">{{ item.SubTitle }}</div>
              <div class="footer fs12">
                <span class="csp mr1"><Fav :type="3" :id="item.Id" :status="item.is_favoite"/></span>
                <span class="time" v-if="item.ReleaseDate">发布时间：{{ $utils.formatTime(item.ReleaseDate) }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="no-data" v-else>暂无数据</div>
    </div>
    <el-pagination
      layout="prev, pager, next"
      class="tc mt2"
      :page-size="params.limit || 3"
      :total="total"
      v-if="showPagination"
      @current-change="onPageChange"
    ></el-pagination>
  </div>
</template>

<script>
import ModuleHeader from './common/ArtHeaderLeft';
import Fav from '@/components/fav';

export default {
  components: { ModuleHeader, Fav },
  props: {
    lineNum: { type: Number, default: 3 },
    params: { type: Object, default: () => ({}) },
    showPagination: { type: Boolean, default: false },
    showMore: { type: Boolean, default: true },
  },
  data() {
    return {
      status: ['报名中', '进行中', '已结束'], // status 0: 报名中 1：进行中；2：已结束
      list: [],
      loading: true,
      page: 1,
      total: 0,
    };
  },
  computed: {
    style() {
      return {
        width: 100 / this.lineNum - 0.8 + '%',
        margin: '0 0.4%',
      };
    },
  },
  created() {
    this.getList();
  },
  methods: {
    onPageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      this.$apis.train
        .getTrainCourse({ page: this.page, limit: 3, ...this.params })
        .then(res => {
          this.list = res.data;
          this.total = res.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    go2trainingClass() {
      // eslint-disable-next-line no-unused-vars
      const { limit, ...rest } = this.params;
      const query = this.$utils.obj2query(rest);
      location.assign(`${this.$constants.routers.train.link}?${query}`);
    },

    go2detail(item) {
      location.href = `${this.$constants.routers.videoDetail.link}?type=train&id=${item.Id}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/css/variables';
.training-class {
  .header {
    display: flex;
    font-size: 22px;
    border-bottom: 1px solid #ccc;
    .title {
      padding: 6px 0;
      border-bottom: 4px solid $theme-color;
    }
    .more {
      flex: 1;
      text-align: right;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .list {
    flex-wrap: wrap;
    .item {
      padding: 30px 0 2%;
      border-bottom: 4px solid $theme-red;
      &:last-child {
        margin-right: 0;
      }
      .pic {
        @include padding-height(100%);
        overflow: hidden;
        .img {
          width: 100%;
        }
      }
      .content {
        padding: 0 10px;
        .title {
          color: $font-main;
          font-size: 20px;
          font-weight: bold;
          height: 40px;
        }
        .info {
          color: $font-sec;
          font-size: 14px;
          height: 30px;
          line-height: 15px;
        }
        .footer {
          padding-top: 10px;
          color: $font-sec;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
