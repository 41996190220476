import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';
import ad from './ad';
import app from './app';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    ad,
    app,
  },
});

export default store;
window.$store = store;
