<template>
  <div class="detail" v-loading="loading">
    <el-breadcrumb separator="·">
      <el-breadcrumb-item><a :href="$constants.routers.index.link">首页</a></el-breadcrumb-item>
      <el-breadcrumb-item>
        <a :href="$constants.routers.news.link">{{ apiMap[this.type].title }}</a>
      </el-breadcrumb-item>
      <!--
      <el-breadcrumb-item>{{ detail.Title }}</el-breadcrumb-item>
      -->
    </el-breadcrumb>
    <h3 class="title">{{ detail.Title }}</h3>
    <p class="sub-title">{{ Keywords }}</p>
    <p class="sub-title">
      <span v-if="detail.Source">
        来源：{{ detail.Source }}
        <span class="mh1">·</span>
      </span>
      <span>{{ $utils.formatTime(detail.ReleaseDate) }}</span>
    </p>
    <!-- <div class="tc">
      <img :src="detail.Cover" class="max-full-w" alt="" />
    </div> -->
    <div class="content fs14" v-html="detail.Content"></div>
    <div class="mt4">
      <div v-if="detail.Source === 'MIMS'">
        <span class="fwb">转载声明：</span>
        <span class="gray9">本文为MIMS原创文章，转载请注明出处及作者，否则为侵权。</span>
      </div>
      <div>
        <span class="fwb" v-if="Keywords">关键词：</span><span class="gray9">{{ Keywords }}</span>
      </div>
    </div>
    <div class="tr gray6">阅读量：{{ detail.ReadCount }}</div>
    <div class="pv4" v-if="type === 'academ'">
      <ArtHeaderRight title="附件"></ArtHeaderRight>
      <div class="pv2">
        <div class="pv1" v-for="(item, index) of detail.file" :key="index">
          <a :href="item.FilePath">{{item.FileName}}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArtHeaderRight from '../common/ArtHeaderRight'
import * as previewApi from '../../apis/previewApi';
export default {
  name: 'PreviewDetail',
  components: {ArtHeaderRight},
  props: {},
  data() {
    let { id, type, ResType } = this.$utils.query2Obj(location.search);
    return {
      id,
      type,
      ResType,
      detail: {
        Keywords: '',
      },
      loading: true,
      // 1会议2资讯3培训课堂4侃侃病例
      apiMap: {
        meeting: { api: previewApi.getMeetingDetail, title: '会议' },
        news: { api: previewApi.getInformationDetail, title: '新闻热点' },
        disease: { api: previewApi.getDiseaseDetail, title: '疾病' },
        meidihui: { api: previewApi.getMeidihuiDetail, title: '美迪会' },
        academ: {api: previewApi.getMagazineDetail, title: '权威原版'}
      },
    };
  },
  computed: {
    Keywords() {
      return (this.detail.Keywords || '').split(',').join(' | ');
    },
  },
  created() {
    if (this.ResType) {
      this.type = this.$constants.resTypeMap[this.ResType];
    }
    const { api, title } = this.apiMap[this.type] || this.apiMap.news;
    document.title = title;
    console.log(this.id);
    api(this.id)
      .then(res => {
        console.log('data:', res.data);
        this.detail = res.data;
        this.loading = false;
      })
      .catch(err => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    handClick() {
      console.log('click');
      location.assign(this.href + '.html');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '../../assets/css/variables.scss';
  .content {
    white-space: pre-wrap;
  }
  ::v-deep .el-breadcrumb__inner.is-link,
  ::v-deep .el-breadcrumb__inner a {
    color: red !important;
  }
  ::v-deep .el-divider {
    background: red;
  }
  .detail {
    min-height: 300px;
    .title,
    .sub-title {
      line-height: 2.5;
    }
    .module-title {
      padding: 0 6px;
      margin: 6px 0 10px;
      border-left: 2px solid red;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .img {
      width: 50%;
      margin: 20px 20%;
    }
    .list {
    }
  }
</style>
