<template>
  <span>
    <!-- {{ value }}=={{ regionMap[value] }} -->
    <span v-if="disabled && !value"></span>
    <el-cascader
      v-else
      size="small"
      class="full-w"
      :value="regionMap[value]"
      :options="regionOptions"
      :disabled="disabled"
      @change="onChange"
    ></el-cascader>
  </span>
</template>
<script>
export default {
  name: '',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [Number, String] },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      regionOptions: [],
      regionMap: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.$apis.user.getRegion().then(res => {
      this.regionOptions = res.list;
      this.regionMap = res.map;
    });
  },
  mounted() {},
  methods: {
    onChange(v) {
      this.$emit('change', v[v.length - 1]);
    },
  },
};
</script>
<style lang="scss" scoped></style>
