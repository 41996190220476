<template>
  <div class='exchange'>
    <div class='center' v-if="detail">
      <div><h2>阅读“{{detail.title}}”需要花费{{detail.price}}积分兑换</h2></div>
      <div class="d2 fs15">当前可用积分：{{detail.userPoint}}   兑换待扣除积分：{{detail.price}}</div>
      <div class="d3">
        <el-button type="text" @click="doSubmit" class="" :disabled="loading" size="mini">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'exchange',
  props:{
    detail:{ type: Object, default: () => ({}) },
    isFee:{type:Boolean,default:false}
  },
  data(){
    let { id, type, ResType } = this.$utils.query2Obj(location.search);
    return {
      id,
      type,
      ResType,
      loading:false
    }
  },
  methods:{
    doSubmit(){
      this.loading=true;
      this.$apis.resources.exchangeRes({Id:this.id,ResType:this.detail.resType}).then(result=>{
        if(result.code===2){
          this.$emit("update:isFee", true);
        }
      }).finally(()=>{
        this.loading=false;
      });
    }
  }
};
</script>

<style scoped lang='scss'>
.exchange {
  position: relative;
  .center {
    width: 500px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    .d2 {
      color: #ec1c24;
      font: 700 20px '';
      margin-top: 40px;
      width: 500px;
    }
    .d3 {
      width: 500px;
      .el-button,
      .el-button--text {
        color: #fff;
        font: 700 20px '';
        background: #ec1c24;
        border: 0;
        width: 228px;
        margin-top: 25px;
      }
    }
  }
}
</style>
