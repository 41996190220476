<template>
  <div class="detail" v-loading="loading">
    <el-breadcrumb separator="·">
      <el-breadcrumb-item><a :href="$constants.routers.index.link">首页</a></el-breadcrumb-item>
      <el-breadcrumb-item>
        <a :href="$constants.routers.news.link">{{ apiMap[this.type].title }}</a>
      </el-breadcrumb-item>
      <!--<el-breadcrumb-item>{{ detail.Title }}</el-breadcrumb-item>-->
    </el-breadcrumb>
    <h3 class="title">{{ detail.Title }}</h3>
    <p class="sub-title">关键词：{{ Keywords }}</p>
    <p class="sub-title">
      <span v-if="detail.Source">
        来源：{{ detail.Source }}
        <span class="mh1">·</span>
      </span>
      <span>{{ $utils.formatTime(detail.ReleaseDate) }}</span>
      <span class="fr">
        分享到：
        <el-popover
          placement="top-start"
          title="分享到微信"
          width="200"
          trigger="hover">
          <QCode :text="href"></QCode>
          <svg-icon slot="reference" icon-class="wechat" />
        </el-popover>
        <!--<a class="ml2" @click="share('weixin')">-->
          <!--<svg-icon icon-class="wechat" />-->
        <!--</a>-->
        <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
          content="点击直接分享到微博">
          <a class="ml2" slot="reference" @click="share('sina')">
            <svg-icon icon-class="sina" />
          </a>
        </el-popover>
      </span>
    </p>
    <!-- <div class="tc">
      <img :src="detail.Cover" class="max-full-w" alt="" />
    </div> -->
    <div class="content fs14" v-html="detail.Content"></div>
    <div class="mt4">
      <div v-if="(detail.Source || '').toUpperCase === 'MIMS'">
        <span class="fwb">转载声明：</span>
        <span class="gray9">本文为MIMS原创文章，转载请注明出处及作者，否则为侵权。</span>
      </div>
      <div>
        <span class="fwb" v-if="Keywords">关键词：</span><span class="gray9">{{ Keywords }}</span>
      </div>
    </div>
    <div class="tr gray6">阅读量：{{ detail.ReadCount }}</div>
    <div class="pv4" v-if="type === 'academ'">
      <ArtHeaderRight title="附件" :more="false"></ArtHeaderRight>
      <div class="pv2">
        <div class="pv1" v-for="(item, index) of detail.file" :key="index">
          <a :href="item.FilePath" target="_blank">{{item.FileName}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArtHeaderRight from '../common/ArtHeaderRight'
import QCode from '../../components/QCode'
export default {
  name: 'Article',
  components: {ArtHeaderRight, QCode},
  props: {},
  data() {
    let { id, type, ResType } = this.$utils.query2Obj(location.search);
    const href = location.href
    return {
      href,
      id,
      type,
      ResType,
      detail: {
        Keywords: '',
      },
      loading: true,
      // 1会议2资讯3培训课堂4侃侃病例5美迪会 6权威版原 7商品
      apiMap: {
        meeting: { api: this.$apis.meeting.getMeetingDetail, title: '会议' },
        news: { api: this.$apis.news.getNewsDetail, title: '新闻热点' },
        disease: { api: this.$apis.disease.getDiseaseDetail, title: '疾病' },
        meidihui: { api: this.$apis.club.getMeidihuiDetail, title: '美迪会' },
        academ: {api: this.$apis.app.getMagazineDetail, title: '权威原版'}
      },
    };
  },
  computed: {
    Keywords() {
      return (this.detail.Keywords || '').split(',').join(' | ');
    },
  },
  created() {
    if (this.ResType) {
      this.type = this.$constants.resTypeMap[this.ResType];
    }
    const { api, title } = this.apiMap[this.type] || this.apiMap.news;
    document.title = title;
    api({ Id: this.id })
      .then(res => {
        console.log("api:",res);
        this.detail = res.data;
        this.loading = false;
        if(this.detail && (this.detail.SubscriptionMode===1 || this.detail.Price>0)) {
          console.log("aaa");
        }
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    handClick() {
      console.log('click');
      location.assign(this.href + '.html');
    },
    share(type){
      //qq空间分享接口
      if(type == 'qzone'){
        window.open('https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url='+document.location.href+'?sharesource=qzone&title=标题&pics=图片地址&summary= 描述')
      }
      //新浪微博接口的传参
      if(type=='sina'){
        window.open('http://service.weibo.com/share/share.php?url='+encodeURIComponent(document.location.href)+'?sharesource=weibo&title='+this.detail.Title+'&pic=图片&appkey=微博平台申请的key');
      }
      //qq好友接口的传参
      if(type == 'qq'){
        window.open('http://connect.qq.com/widget/shareqq/index.html?url='+document.location.href+'?sharesource=qzone&title=标题&pics=图片地址&summary= 描述')
      }
      //微信分享
      var url = window.location.href,
        encodePath = encodeURIComponent(url),
        targetUrl = 'http://qr.liantu.com/api.php?text=' + encodePath;
      window.open(targetUrl,'weixin', 'height=320, width=320')
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
.svg-icon {
  width: 21px;
  height: 21px
}
.content {
  white-space: pre-wrap;
}
::v-deep .el-breadcrumb__inner.is-link,
::v-deep .el-breadcrumb__inner a {
  color: red !important;
}
::v-deep .el-divider {
  background: red;
}
.detail {
  min-height: 300px;
  .title{
    line-height: 1.5;
  }
  .sub-title {
    line-height: 2.5;
  }
  .module-title {
    padding: 0 6px;
    margin: 6px 0 10px;
    border-left: 2px solid red;
  }
  .title {
    font-size: 36px;
    font-weight: bold;
  }
  .img {
    width: 50%;
    margin: 20px 20%;
  }
  .list {
  }
}
</style>
