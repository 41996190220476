<template>
  <div>
    <span>这是一段信息</span>
  </div>
</template>
<script>
export default {
  name: '',
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
