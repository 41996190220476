import { render, staticRenderFns } from "./clause.vue?vue&type=template&id=08c9c576&scoped=true&"
import script from "./clause.vue?vue&type=script&lang=js&"
export * from "./clause.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c9c576",
  null
  
)

export default component.exports