import $apis from '@/apis';

export default {
  namespaced: true,
  state: {
    departments: [],
    hotKeywords: [],
    externalLinks: [],
    // 1会议2资讯3培训课堂4侃侃病例 5美迪会 6权威版原 7商品
    articleTypes: ['', 'meeting', 'news', 'train', 'disease', 'meidihui', 'academ', 'product']
  },
  getters: {
    departmentsMap(state) {
      return state.departments.reduce((map, item) => {
        map[item.Id] = item;
        return map;
      }, {});
    },
  },
  mutations: {
    setDepartments(state, payload) {
      state.departments = payload;
    },
    setHotKeywords(state, payload) {
      state.hotKeywords = payload;
    },
    setExternalLinks(state, payload) {
      state.externalLinks = payload;
    },
  },
  actions: {
    getSpecial({ commit }) {
      return $apis.app
        .getSpecial()
        .then(res => {
          commit('setDepartments', res.data[0] || []);
          commit('setHotKeywords', res.data[1] || []);
        })
        .catch(err => {
          console.err(err);
        });
    },
    getExternalLinks({ commit }) {
      return $apis.app
        .getExternalLink()
        .then(res => {
          commit('setExternalLinks', res.data || []);
        })
        .catch(err => {
          console.err(err);
        });
    },
  },
};
