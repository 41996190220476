<template>
  <div class="list ph2 pv2 oa tl" flex v-loading="loading">
    <div class="half-w">
      <div v-for="(item, idx) in list1" :key="idx" class="item csp" @click="go2search(item)">
        <div class="cn">{{ item.MainClass }}</div>
        <div class="en">{{ item.MainClass_en }}</div>
      </div>
    </div>
    <div class="half-w">
      <div v-for="(item, idx) in list2" :key="idx" class="item csp" @click="go2search(item)">
        <div class="cn">{{ item.MainClass }}</div>
        <div class="en">{{ item.MainClass_en }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    category: { type: String },
    link: { type: String },
  },
  data() {
    return {
      list: [],
      loading: true,
    };
  },
  computed: {
    list1() {
      const { list } = this;
      return list.slice(0, Math.ceil(list.length / 2));
    },
    list2() {
      const { list } = this;
      return list.slice(Math.ceil(list.length / 2));
    },
  },
  created() {
    this.$apis.drug
      .getDrugClasslist()
      .then(res => {
        this.list = res.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  mounted() {},
  methods: {
    go2search(item) {
      location.assign(`${this.$constants.routers.drugClass.link}?ClassCode=${item.ClassCode}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  width: 263px;
  max-height: 60vh;
}
.item {
  padding: 5px 3px;
  line-height: 14px;
  word-break: normal;
}
.cn {
}
.en {
  color: $color-primary;
}
</style>
