<template>
  <ArticleModule icon="icon-case" title="侃侃病例" :href="listUrl" v-loading="loading" @current-change="onPageChange">
    <!-- {{ diseaseCaseList.length }} -->
    <template v-if="diseaseCaseList.length">
      <DiseaseItem
        v-for="(item, idx) in diseaseCaseList"
        :key="idx"
        :item="item"
        :favType="4"
        :href="`${$constants.routers.newsDetail.link}?type=disease&id=${item.Id}`"
      />
    </template>
    <div class="no-data" v-else>暂无数据</div>
  </ArticleModule>
</template>
<script>
import ArticleModule from './article/Index';
import DiseaseItem from '@/components/DiseaseItem';

export default {
  components: { ArticleModule, DiseaseItem },
  props: {
    params: { type: Object, default: () => ({}) },
    list: { type: Array },
    useList: { type: Boolean, default: false },
  },
  data() {
    return {
      diseaseCaseList: [],
      loading: false,
      page: 1,
    };
  },
  computed: {
    listUrl() {
      // eslint-disable-next-line no-unused-vars
      const { limit, ...rest } = this.params;
      const query = this.$utils.obj2query(rest);
      return `${this.$constants.routers.typicalCase.link}?${query}`;
    },
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          this.diseaseCaseList = nv;
        }
      },
    },
  },
  created() {
    if (!this.useList) {
      this.getList();
    }
  },
  mounted() {},
  methods: {
    onPageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$apis.disease
        .getDiseaseCases({ limit: 3, page: this.page, ...this.params })
        .then(res => {
          this.diseaseCaseList = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
