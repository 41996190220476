import * as user from './user';
import * as drug from './drug';
import * as app from './app';
import * as news from './news';
import * as train from './train';
import * as meeting from './meeting';
import * as disease from './disease';
import * as shop from './shop';
import * as club from './club';
import * as ad from './ad';
import * as resources from './resources';

export default {
  user,
  drug,
  app,
  news,
  train,
  meeting,
  disease,
  shop,
  club,
  ad,
  resources
};
