import $http from '@/utils/request';
import $apis from './api';

export const getTreatmentTags = () => {
  return $http.get($apis.treatmentTags).then(res => {
    return {
      data: res.data.map(item => ({ ...item, SpecialtyId: item.SpecialtyId + '', Id: item.Id + '' })),
    };
  });
};

export const getDrugRecommend = () => {
  return $http.get($apis.preferredlinks);
};

export const getSpecialtyDisease = () => {
  return $http.get($apis.specialtyDisease).then(res => {
    return res.data.reduce((map, item) => {
      map[item.Specialty] = item;
      return map;
    }, {});
  });
};

export const getSearchDrugList = params => {
  return $http.get($apis.drugList, { params }).then(res => {
    return {
      data: res.data,
      total: res.total,
    };
  });
};

export const getSearchDrugData= params => {
  return $http.get($apis.searchDrug, { params }).then(res => {
    return {
      data: res.data,
      total: res.total,
    };
  });
};

export const getDruggistList = params => {
  return $http.get($apis.druggistList, { params }).then(res => {
    return {
      data: res.data,
      total: res.total,
    };
  });
};

export const getDrugDetail = params => {
  return $http.get($apis.drugDetail, { params }).then(res => {
    const { data } = res;
    if(!data){
      return res;
    }

    const Brife = data.Brife || {Class:[]};
    return {
      data: {
        ...data,
        Brife: {
          ...Brife,
          Class: Brife.Class.map(i => i.ClassDescription).join(' / '),
        },
        mainCompany: (data.Company || [])[0] || {},
      },
    };
  });
};

export const searchDrugInformations =params =>{
  return $http.get($apis.searchDrugInformations, { params }).then(res => {
    const { data } = res;
    if(!data){
      return res;
    }

    const Brife = data.Brife || {Class:[]};
    return {
      data: {
        ...data,
        Brife: {
          ...Brife,
          Class: Brife.Class.map(i => i.ClassDescription).join(' / '),
        },
        mainCompany: (data.Company || [])[0] || {},
      },
    };
  });
};

export const searchDrugDiseasecases =params =>{
  return $http.get($apis.searchDrugDiseasecases, { params }).then(res => {
    const { data } = res;
    if(!data){
      return res;
    }

    const Brife = data.Brife || {Class:[]};
    return {
      data: {
        ...data,
        Brife: {
          ...Brife,
          Class: Brife.Class.map(i => i.ClassDescription).join(' / '),
        },
        mainCompany: (data.Company || [])[0] || {},
      },
    };
  });
};

export const getDrugIcon = params => {
  return $http.get($apis.drugIcon, { params }).then(res => {
    let {pdf = '', PoisonClass: icon} = res.data.icon
    icon = (icon&&icon.trim())?icon.trim().split(' '):[]
    return {
      pdf,
      icon
    };
  });
};

export const getDrugClasslist = () => {
  return $http.get($apis.drugClasslist).then(res => {
    return {
      data: res.data.map(item => {
        const { MainClass, MainClass_en, list } = item;
        return {
          ...item,
          id: item.ClassCode,
          text: MainClass,
          label: `${MainClass}[${MainClass_en}]`,
          children: list.map(item => ({
            text: MainClass,
            label: `${item.ClassDescription}[${item.ClassDescription_en}]`,
            desc: item.ClassDescription,
          })),
        };
      }),
    };
  });
};

export const getDrugList = params => {
  return $http.get($apis.alphabet, { params }).then(res => {
    return {
      total: res.total,
      data: res.data.map(i => {
        const { name, safeUrl, FullName, Name } = i;
        return {
          ...i,
          showName:
            params.searchBy === 'Company' ? [Name, FullName].join(' ') : [name, safeUrl].join(' '),
        };
      }),
    };
  });
};

export const companyInfo = params => {
  return $http.get($apis.companyInfo, { params }).then(res => {
    return {
      data: res.data[0]
    }
  })
};

export const searchDrugImge = params => {
  return $http.get($apis.searchDrugImge, { params }).then(res => {
    return {
      data: res.data,
      total: res.total,
    };
  });
};
export const searchDrugColor = params => {
  return $http.get($apis.searchDrugColor, { params }).then(res => {
    return {
      data: res.data,
      total: res.total,
    };
  });
};
export const getSearchShape = params => {
  return $http.get($apis.searchDrugImge, { params }).then(res => {
    return {
      data: res.data,
      total: res.total,
    };
  });
};
export const chui = params => {
  return $http.get($apis.chui, { params }).then(res => {
    return {
      data: res
    }
  })
};
