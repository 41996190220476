<template>
	<div class="user-form">
		<div class="user-form-bg rel">
			<!-- <div flex>
        <div>======{{ formData }}</div>
      </div> -->
			<el-form :model="formData" :rules="rules" ref="form" label-width="140px">
				<el-form-item class="form-title">
					<h2 class="fs18 color-primary mb2">请填写您的信息</h2>
				</el-form-item>
				<el-row :gutter="20">
					<el-col class="col" :span="12">
						<el-form-item label="账户名：" prop="Uid">
							<el-input size="small" v-model="formData.Uid" :disabled="isEdit" placeholder="请输入账户名">
							</el-input>
						</el-form-item>
						<el-form-item label="登录密码：" prop="PasswordHash" v-if="!isEdit">
							<el-input size="small" v-model="formData.PasswordHash" type="password"
								placeholder="请输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="手机号：" prop="PhoneNumber">
							<Phone v-model="formData.PhoneNumber" @codeSended="codeSended = true" />
						</el-form-item>
						<el-form-item label="验证码：" prop="VerifyCode">
							<el-input size="small" v-model="formData.VerifyCode" placeholder="请输入验证码"></el-input>
						</el-form-item>
						<el-form-item label="邮箱：" prop="Email">
							<el-input size="small" v-model="formData.Email" placeholder="请输入邮箱地址"></el-input>
						</el-form-item>
						<el-form-item label="真实姓名：" prop="Name">
							<el-input size="small" v-model="formData.Name" placeholder="请输入真实姓名"></el-input>
						</el-form-item>
						<el-form-item label="昵称：" prop="NickName">
							<el-input size="small" v-model="formData.NickName" placeholder="请输入昵称"></el-input>
						</el-form-item>
					
						<el-form-item label="性别：" prop="Sex">
							<el-radio-group v-model="formData.Sex">
								<el-radio :label="0">男</el-radio>
								<el-radio :label="1">女</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="城市：" prop="City">
							<CitySelector v-model="formData.City" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="职业：" prop="profession" size="small">
							<Career v-model="formData.profession" />
						</el-form-item>
						<template v-if="careerInHospital">
							<el-form-item label="医院：" prop="Hospital" :key="`Hospital${rules.Hospital.length}`">
								<el-input size="small" v-model="formData.Hospital" placeholder="请输入医院"></el-input>
							</el-form-item>
							<el-form-item label="科室：" prop="Department" :key="`Department${rules.Department.length}`">
								<Department v-model="formData.Department" />
							</el-form-item>
							<el-form-item label="职称：" prop="PositionTitle"
								:key="`PositionTitle${rules.PositionTitle.length}`">
								<PositionTitle v-model="formData.PositionTitle" />
							</el-form-item>
							<el-form-item label="医院地址：" prop="HospitalAddr">
								<el-input size="small" v-model="formData.HospitalAddr" placeholder="请输入医院地址"></el-input>
							</el-form-item>
							<el-form-item label="职业编号：" prop="DoctorNo">
								<el-input size="small" v-model="formData.DoctorNo" placeholder="请输入职业编号"></el-input>
							</el-form-item>
						</template>
						<el-form-item label="微信号：" prop="WechatId">
							<el-input size="small" v-model="formData.WechatId" placeholder="请输入微信号"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="关注的治疗领域：" prop="AttentionTags">
							<AttentionTags v-model="formData.AttentionTags" />
						</el-form-item>
					</el-col>
				</el-row>
				<div>
				
					<el-form-item v-if="!isEdit" class="mb0">
						<el-checkbox v-model="clauseReaded" @change="checkall"></el-checkbox>
						<span class="ml2 csp">
							我已阅读<a class="link" :href="$constants.routers.terms.link" target="_blank">《服务条款》</a>
							和<a class="link" :href="$constants.routers.privacy.link" target="_blank">《隐私保密制度》</a>相关条款
						</span>
					</el-form-item>
					<el-form-item class="mb0">
						<el-checkbox v-model="formData.UserType" :true-label="2" :false-label="1">我是医疗专业人士</el-checkbox>
					</el-form-item>
					<el-form-item class="mb0">
						<el-checkbox v-model="formData.EmailConfirmed" :true-label="1" :false-label="0">同意接收发送邮件</el-checkbox>
					</el-form-item>
				</div>
				<!-- <div>{{ formData4submit }}</div> -->
				<el-button class="btn mt2" @click="submitForm">{{ isEdit ? '修改' : '注册' }}</el-button>
			</el-form>
			<div class="corner abs tr">
				<img src="../../assets/image/register-logo.png" width="230" />
				<p class="fs24 mt1 color-primary fwb">EMPOWERING HEALTHCARE COMMUNITIES</p>
			</div>
		</div>
		<!--<p class="footer tc mv2 fs12">这里要写一下信息只能用来在本网站登录或者进行相操作使用</p>-->
		<el-dialog title="条款" :visible.sync="clauseVisible" width="30%">
			<Clause />
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="clauseVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		validMobile,
		validPassword,
		createRangeLength,
		validEmail
	} from '@/utils/validate';
	import Clause from './clause';
	import Department from '@/components/department-offline';
	import AttentionTags from '@/components/attention-tags';
	import CitySelector from '@/components/city-selector';
	import Phone from '@/components/phone';
	import Career from '@/components/career';
	import PositionTitle from '@/components/position-title';

	const getRequired = () => ({
		required: true,
		message: '必填项',
		trigger: 'blur'
	});

	export default {
		components: {
			Clause,
			Department,
			AttentionTags,
			CitySelector,
			Phone,
			Career,
			PositionTitle
		},
		props: {
			// register注册；edit编辑
			type: {
				type: String,
				default: 'register'
			},
		},
		data() {
			return {
				clauseVisible: false,
				formData: {
					Uid: '',
					PasswordHash: '',
					Name: '',
					PhoneNumber: '',
					VerifyCode: '',
					Sex: 0,
					City: '',
					AttentionTags: [],
					Hospital: '',
					Department: '',
					PositionTitle: '',
					HospitalAddr: '',
					DoctorNo: '',
					WechatId: '',
					UserType: 1,
					Solt: 'Solt',
					Avatar: 'xxx',
					NickName: '',
					EmailConfirmed:0,
				},
				clauseReaded: false,
				regionOptions: [],
				avatars: [],
				codeSended: false,
				rules: {
					PhoneNumber: [getRequired(), {
						validator: validMobile,
						trigger: 'blur'
					}],
					profession: [getRequired()],
					Hospital: [getRequired()],
					PositionTitle: [getRequired()],
					Department: [getRequired()],
					HospitalAddr: [],
					VerifyCode: [getRequired()],
					AttentionTags: [getRequired()],
					Uid: [
						getRequired(),
						{
							validator: createRangeLength({
								min: 3,
								max: 20,
								label: '账户名',
								tip: '账户名长度不得低于3位,且只能由字母和数字组成'
							}),
							trigger: 'change'
						},
					],
					PasswordHash: [
						getRequired(),
						{
							validator: validPassword,
							trigger: 'change'
						},

						{
							validator: createRangeLength({
								min: 6,
								max: 20,
								label: '密码',
								tip: '密码长度不得低于6位,且只能由字母和数字组成'
							}),
							trigger: 'change'
						},
					],
					Email: [	getRequired(),{
						validator: validEmail,
						trigger: 'change'
					}],
				},
			};
		},
		computed: {
			isEdit() {
				return this.type === 'edit';
			},
			showVerifyCode() {
				return this.type === 'register' || this.codeSended;
			},
			formData4submit() {
				const {
					formData,
					formData: {
						AttentionTags
					},
					avatars,
				} = this;

				const Avatar = (avatars.filter(i => +i.Sex === +formData.Sex)[0] || {}).ImagePath || '';
				return {
					...formData,
					// 省市只取到市级别
					AttentionTags: AttentionTags.join(','),
					Avatar,
				};
			},
			careerInHospital() {
				return [1, 2, 3, 4].indexOf(this.formData.profession) > -1;
			},
		},
		watch: {},
		created() {
			this.$apis.user.getUserInfo().then(res => {
				if (this.isEdit) {
					this.formData = res.data;
				} else {
					if (res.data.Uid) {
						this.$message.success('已登录');
						setTimeout(() => {
							location.replace(this.$constants.routers.index.link);
						}, 1000);
					}
				}
			});

			this.$apis.user.getAvatars().then(res => {
				this.avatars = res;
			});
		},
		methods: {
			checkall(){
			
				if(this.clauseReaded==true){
					this.formData.UserType=2
					this.formData.EmailConfirmed=1
				
				}else{
					this.formData.UserType=1
					this.formData.EmailConfirmed=0
				
				}
			},
			submitForm() {
				this.formData4submit;
				this.$refs.form.validate(valid => {
					if (valid) {
						if (!this.isEdit && !this.clauseReaded) {
							this.$message.error('请先同意相关条款');
						} else {
							if (this.isEdit) {
								this.$apis.user.editInfo(this.formData4submit).then(() => {
									this.$message.success('修改成功');
									this.$emit('success');
								});
							} else {
								this.$apis.user.doRegister(this.formData4submit).then(() => {
									this.$message.success('注册成功');
									setTimeout(() => {
										location.href = this.$constants.routers.login.link;
									}, 1000);
								});
							}
						}
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.user-form {
		position: relative;
		padding: 45px;

		::v-deep {
			.el-input {
				max-width: 300px;
			}

			.el-form-item {
				margin: 0 0 18x;
			}

			.el-form-item__error {
				top: 90%;
			}

			.el-form-item__label,
			.el-form-item__content {
				line-height: 28px;
			}
		}

		.user-form-bg {
			padding: 38px 108px;
			background: rgba(255, 255, 255, 1);
			box-shadow: 0px 0px 16px 1px rgba(51, 51, 51, 0.18);
			border-radius: 11px;
			background: url('../../assets/image/register-bg.png');

			h3 {
				color: #ec1c24;
				font: 700 24px '';
			}

			.btn {
				background: #ec1c24;
				height: 50px;
				width: 359px;
				color: #fff;
				font: 700 24px '';
				border: 0;
			}
		}

		.corner {
			right: 68px;
			bottom: 38px;
		}
	}
</style>
