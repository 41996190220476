<template>
  <el-carousel class="my-carousel" trigger="click" :autoplay="false">
    <el-carousel-item class="item" v-for="(item, index) in list" :key="index">
      <a :href="item.TargetUrl" target="_blank" class="rel full db">
        <img :src="item.ImagePath" class="img" />
        <!-- <h3 class="small line1">{{ item.Title }}</h3> -->
        <div class="banner-title" v-if="showTitle">{{ item.Description }}</div>
      </a>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  props: {
    id: { type: Number },
    showTitle: { type: Boolean, default: true },
    params: {type: Object, default: () => ({})}
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    const params = Object.assign({}, { AscriptionId: this.id }, this. params)
    this.$apis.app.getBanner(params).then(res => {
      this.list = res.data;
    });
  },
};
</script>

<style lang="scss" scoped></style>
