<template>
  <a :href="detailUrl+item.Id" target="_blank">
    <div class="item">
      <div flex-box="0" class="pic-w oh mr3 csp">
        <div class="pic-i">
          <img :src="item.Cover" alt="" class="full img-placeholder" />
          <!--<div class="list-item-tag" v-if="tag">{{ tag }}</div>-->
        </div>
      </div>
      <div class="content rel pl3" flex-box="1">
        <div class="title line2 csp fs15">{{ item.Title }}</div>
        <div class="art-content line2 fs14 csp">{{ item.SubTitle }}</div>
        <div class="bottom fs12" flex="cross:center">
          <div class="left" flex-box="1">
            <div v-if="item.specialty.length>0">
              <div class="line1">所属领域：{{ (item.specialty || []).map(i => i.Name).join('，') }}</div>
            </div>
          </div>
          <div class="tr ml2" flex-box="0">
            <span @click.stop class="csp"><Fav :type="1" :id="item.Id" :status="item.is_favoite"/></span>
            <span class="ml2"><span class="el-icon-time mr1"></span>{{ $utils.formatTime(item.ReleaseDate) }}</span>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import Fav from '@/components/fav';
export default {
  name: '',
  components: { Fav },
  props: {
    item: { type: Object, default: () => ({}) },
  },
  data() {
    const { id } = this.$utils.query2Obj(location.search);
    return {
      id,
      detailUrl:this.$constants.routers.newsDetail.link+"?type=meeting&id="
    };
  },
  computed: {
    tag() {
      let result = '';
      if (location.pathname === this.$constants.routers.specialty.link) {
        result = (this.$store.getters['app/departmentsMap'][this.id] || {}).Name;
      }

      return result;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    go2detail() {
      location.href = `${this.$constants.routers.newsDetail.link}?type=meeting&id=${this.item.Id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/variables.scss';
.bottom {
  position: absolute;
  left: 20px;
  bottom: 0;
  right: 0;
  color: $font-content;
  padding: 10px 0 0;
}
.item {
  display: flex;
  overflow: hidden;
  padding: 15px 0;
  .content {
    .title {
      color: $font-title;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 6px;
    }
    .art-content {
      color: $font-content;
      /*line-height: 22px;*/
    }
  }
}
.item {
  &:last-child {
    margin: 0 !important;
  }
}
</style>
