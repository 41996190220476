<template>
  <div class="dib csp" @click="toggleFav">
    <svg-icon icon-class="fav" v-if="statusInternal === 2" class="vm active" width="13" />
    <template v-else> <svg-icon icon-class="fav" width="13" class="vm" /><span> 喜欢</span></template>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    type: { type: Number },
    id: { type: Number },
    status: { type: Number },
  },
  data() {
    return {
      // 1未收藏，2已收藏
      statusInternal: +this.status,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    toggleFav() {
      this.$utils.checkLogin().then(() => {
        const params = {
          // 1会议2资讯3培训课堂4侃侃病例
          ResType: this.type,
          ResId: this.id,
          // 操作类型 1添加收藏 2取消收藏
          op_type: this.statusInternal === 1 ? 1 : 2,
        };
        this.$apis.user.setFav(params).then(() => {
          if (this.statusInternal === 1) {
            this.statusInternal = 2;
            this.$message.success('收藏成功');
          } else {
            this.statusInternal = 1;
            this.$message.success('已取消收藏');
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
svg {
  color: #cbcbcb;
}
.active {
  color: $color-primary;
}
</style>
