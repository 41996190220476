<template>
  <!-- 药品信息 搜索列表-->
  <div class="drugInformation" v-loading.body.lock="loading">
    <ModuleHeader class="mb4" title="药品信息" :showMore="false" svgIcon="drug"></ModuleHeader>
    <div class="intro" v-if="showIntro">
      <p>创新产品: 卫克泰 Fycompa (吡仑帕奈)</p>
      <ul class="mv2">
        <li>• 第三代胃痛新药。</li>
        <li>• 第三代胃痛新药。</li>
      </ul>
      <p> 没有找到您要检索的内容? 请在所有国家搜索。</p>
    </div>
    <template v-if="drugList.length||Object.keys(drugList).length">
      <DrugItem v-for="(item, idx) in drugList" :key="idx" :item="item"/>
    </template>
    <div class="no-data" v-if='isNoData'>暂无数据</div>
    <div v-if="showPagination" class="mb2 tc">
      <el-pagination
        class="tc"
        v-if="showPagination"
        :showPagination="showPagination"
        :total="total"
        :pageSize="pageSize"
        layout="prev, pager, next"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import ModuleHeader from './common/ArtHeaderLeft';
import DrugItem from './DrugItem';
export default {
  name: 'DrugInformation',
  components: { ModuleHeader, DrugItem },
  props: {
    params: { type: Object, default: () => ({}) },
    pageSize: { type: Number, default: 10 },
    showPagination: { type: Boolean, default: false },
    showIntro: { type: Boolean, default: false},
  },
  data() {
    return {
      drugList: [],
      total: 0,
      loading: true,
      isNoData:false,
      page: 1,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    onPageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
	var api="";
	if(this.params.searchType==1){
		api=   this.$apis.drug
        .getSearchDrugData({ limit: this.pageSize, page: this.page, ...this.params })
	}else if(this.params.searchType==3){
		api=   this.$apis.drug
		.searchDrugImge({ limit: this.pageSize, page: this.page, ...this.params })
	}else if(this.params.searchType==4){
		api=   this.$apis.drug
		.searchDrugColor({ limit: this.pageSize, page: this.page, ...this.params })
	}
      api
        .then(res => {
          this.drugList = res.data;
          console.log("druglist:",res);
			console.log("num",Object.keys(res.data).length)
			if(Object.keys(res.data).length>10){
				this.pageSize=1000
			}
          this.total = res.total;
          this.loading = false;
          if (!this.drugList){
            this.isNoData=true;
          }else{
            if(this.drugList.length==0){
              this.isNoData=true;
            }
          }
        })
        .catch(() => {
          this.loading = false;
          this.isNoData=false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.drugInformation {
  .intro {
    padding: 20px;
    border-radius: 10px;
    background: #E1E1E1;
  }
}
</style>
