<template>
  <div class="magazine">
    <ArtHeaderLeft title="好文推荐" :showMore="false" icon="icon-book" />
    <div class="list" v-loading="loading">
      <template v-for="(item,index) in list">
        <a :href="detailUrl+item.Id" :key="index" target="_blank">
          <div class="item" flex="">
            <div flex-box="0" class="pic-w oh mr3 csp">
              <div class="pic-i">
                <img :src="item.Cover" flex-box="0" class="csp"/>
              </div>
            </div>
            <div class="content ml3 rel" flex-box="1">
              <div class="fs15 fwb line2 csp">{{ item.Title }}</div>
              <div class="gray9 fs12 line2 mt1 csp">{{ item.SubTitle }}</div>
              <div class="bottom gray9 mt1" flex="main:justify">
                <div flex-box="1">
                  <div class="line1">所属领域：{{ (item.specialty || []).map(i => i.Name).join('，') }}</div>
                </div>
                <div class="tr ml2" flex-box="0">
                  <span @click.stop class="csp"><Fav :type="6" :id="item.Id" :status="item.is_favoite"/></span>
                  <i class="el-icon-time mh1"></i>{{ $utils.formatTime(item.ReleaseDate) }}
                </div>
              </div>
            </div>
          </div>
        </a>
      </template>
    </div>

    <div class="pagination mt2">
      <el-pagination
        color
        @current-change="handleSizeChange"
        :current-page="page"
        :page-sizes="[6, 20, 50, 100]"
        :page-size="pagesize"
        layout="prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import ArtHeaderLeft from '../../components/common/ArtHeaderLeft';
import Fav from '@/components/fav';

export default {
  name: 'magazine',
  components: {
    ArtHeaderLeft,
    Fav,
  },
  data() {
    return {
      page: 1, // 初始页
      pagesize: 6, // 每页的数据
      list: [],
      total: 0,
      loading: true,
      detailUrl:this.$constants.routers.newsDetail.link+"?type=academ&id="
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange(size) {
      this.page = size;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$apis.app
        .getMagazine({ limit: this.pagesize, page: this.page })
        .then(res => {
          this.list = res.data;
          this.total = res.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    go2detail(item) {
      location.href = `${this.$constants.routers.newsDetail.link}?type=academ&id=${item.Id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/variables.scss';
.magazine {
  .list {
    min-height: 100px;
    .item {
      margin-top: 10px;
    }
  }
}
.bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  color: $font-content;
  padding: 10px 0 0;
  display: flex;
}
</style>

<style lang="scss">
.pagination {
  text-align: center;
  .el-pagination {
    .el-pager {
      li.active {
        color: #e9334c;
      }
    }
  }
}
</style>
