import $http from '@/utils/request';
import $apis from './api';

export const getProducts = params => {
  return $http.get($apis.product, { params });
};

export const createOrder = params => {
  return $http.get($apis.createOrder, { params });
};

export const getProductDetail = params => {
  return $http.get($apis.productDetail, { params }).then(res => {
    return {
      data: { ...res.data, Pictures: [res.data.Cover, ...(res.data.Pictures || '').split(',').filter(i => i)] },
    };
  });
};
