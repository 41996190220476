<template>
  <div class="latest-meeting">
    <article-header title="热门点击" @click="go2TopHits" :more="showMore"></article-header>
    <div class="list">
      <TrainItem v-for="(item, index) of list" :key="index" :item="item" />
    </div>
  </div>
</template>

<script>
import ArticleHeader from './common/ArtHeaderRight';
import TrainItem from './TrainItem';
export default {
  name: 'TopHits',
  components: { ArticleHeader, TrainItem },
  props: {
    showMore: { type: Boolean, default: true },
    params: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      status: ['报名中', '进行中', '已结束'], // status 0: 报名中 1：进行中；2：已结束
      list: [],
    };
  },
  created() {
    this.$apis.train.getTrainCourse({ limit: 4, order: 'ReadCount', ...this.params }).then(res => {
      this.list = res.data;
    });
  },
  methods: {
    go2TopHits() {
      // eslint-disable-next-line no-unused-vars
      const { limit, ...rest } = this.params;
      const query = this.$utils.obj2query(rest);
      location.assign(`${this.$constants.routers.train.link}?${query}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/css/variables.scss';
.latest-meeting {
  .header {
    display: flex;
    font-size: 22px;
    border-bottom: 1px solid #ccc;
    .title {
      padding: 6px 0;
      border-bottom: 4px solid $theme-color;
    }
    .more {
      flex: 1;
      text-align: right;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .list {
    padding: 12.5px 0;
  }
}
</style>
