<template>
	<div class="header pt6" :class="{ mb8: menu[activeMenuIdx] && menu[activeMenuIdx].children }">
		<div class="main main-inner">
			<div class="logo">
				<a :href="$constants.routers.index.link">
					<img class="img" src="../../../assets/image/logo.png" />
				</a>
			</div>
			<div class="full"></div>
			<div class="search-module">
				<div class="type">
					<div class="search-type">
						<span>搜索：</span>
						<div class="item csp" v-for="item in searchTypeOptions" :key="item.value"
							:class="{ active: searchType === item.value }" @click="searchType = item.value">
							<span>{{ item.label }}</span><span class="mh1 line">|</span>
						</div>
					</div>
				</div>
				<div class="search pt1">
					<el-autocomplete class="full-w" :placeholder="pla" v-model="keywords"
						:fetch-suggestions="querySearchAsync" @keydown.native="go2search" @select="go2search">
						<template v-slot:default="slotProps">
							<div flex="cross: center">
								<span v-html="slotProps.item.value"></span>
								<img v-if="slotProps.item.logo" :src="slotProps.item.logo" class="pl3" height="30">
							</div>
						</template>
						<el-button slot="append" class="el-icon-search" @click="go2search"></el-button>
					</el-autocomplete>
				</div>
			</div>
		</div>
		<div class="dept-list main-inner loading-sm" v-show="showDepartments" v-loading="loadingSpecial">
			<span class="item" :class="{ active: pathname === $constants.routers.index.link }">
				<a :href="$constants.routers.index.link">首页</a>
			</span>
			<!-- 科室 -->
			<span class="item" :class="{ active: +item.Id === +specialtyId }" v-for="item of app.departments"
				:key="item.Id">
				<a :href="`${$constants.routers.specialty.link}?id=${item.Id}`">{{ item.Name }}</a>
			</span>
			<!-- 热点 -->
			<span class="item" v-for="item in app.hotKeywords" :key="item.Id"
				:class="{ active: pathname === $constants.routers.specialtyHot.link }">
				<a :href="`${$constants.routers.specialtyHot.link}?id=${item.Id}`">{{ item.Name }}</a>
			</span>
		</div>
		<!-- 导航 -->
		<div class="main-nav rel">
			<div class="menu main-inner" flex>
				<div class="main-menu" flex>
					<span v-for="(item, idx) in menu" class="main-menu-item csp tc"
						:class="{ current: currentMenuIdx === idx || activeMenuIdx === idx }" :key="idx"
						@mouseenter="currentMenuIdx = idx" @mouseleave="
              currentMenuIdx = '';
              currentMenuIdxCache = idx;
            ">
						<span @click="go2link(item.link)" class="full db item-inner rel" :class="{ icon: item.icon }">{{
              item.label
            }}</span>
						<!-- 子导航 -->
						<div class="sub-menu abs tl" v-show="showSubMenuIdx === idx && item.children.length">
							<div class="sub-menu-inner black full-h">
								<span v-for="(child, idx) in item.children" :key="idx" class="ph3 csp dib full-h">
									<el-popover v-if="child.type === 'dropdown'" placement="bottom" trigger="hover"
										:width="child.width" :visible-arrow="false" popper-class="header-menu-popover">
										<span slot="reference">{{ child.label }}</span>
										<component :is="child.render" v-if="child.render" v-bind="child.data || {}"
											:link="child.link || item.link" @mouseleave.native="currentMenuIdx = ''"
											@mouseenter.native="currentMenuIdx = currentMenuIdxCache"></component>
									</el-popover>
									<a v-else-if="child.link" :href="child.link" class="dib full"
										:class="{ actived: child.isActive }">{{
                    child.label
                  }}</a>
									<span v-else>{{ child.label }}</span>
								</span>
							</div>
						</div>
					</span>
				</div>
				<!-- 用户中心 -->
				<div class="login hidden" :class="{ show: !loadingUserInfo }">
					<template v-if="user.userInfo.Uid">
						<span class="item">
							<a :href="$constants.routers.mine.link">
								<svg-icon icon-class="user-line" />{{ username }}
							</a>
						</span>
						<span class="item csp" @click="doLogout">退出</span>
					</template>
					<template v-else>
						<span class="item">
							<a :href="$constants.routers.login.link">
								<svg-icon icon-class="user-line" />登录
							</a>
						</span>
						<span class="item"><a :href="$constants.routers.register.link">注册</a></span>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapActions,
		mapState
	} from 'vuex';
	import {
		query2Obj
	} from '../../../utils';
	import NameSelector from './name-selector';
	import DrugSelector from './drug-selector';
	export default {
		name: 'HomeHeader',
		components: {},
		props: {
			jump: {
				type: Boolean,
				default: true
			},
		},
		data() {
			const {
				$constants
			} = this;
			const search = this.$utils.query2Obj(location.search);
			return {
				pla: "输入你要搜索条件",
				searchType: search.searchType || '1',
				keywords: search.keywords || '',
				searchTypeOptions: [{
						value: '1',
						label: '药物信息'
					},
					{
						value: '3',
						label: '药物形狀'
					},
					{
						value: '4',
						label: '药物颜色'
					},
					{
						value: '2',
						label: '专科新闻与资讯'
					},
				],
				currentMenuIdx: '',
				loadingUserInfo: true,
				loadingSpecial: true,
				menu: [{
						label: '药品信息',
						link: $constants.routers.tradeName.link,
						icon: 1,
						children: [{
								label: '药名查询',
								type: 'dropdown',
								data: {
									category: 'Brand'
								},
								render: NameSelector
							},
							{
								label: '通用名查询',
								type: 'dropdown',
								data: {
									category: 'Generic'
								},
								render: NameSelector,
							},
							{
								label: '药商查询',
								type: 'dropdown',
								data: {
									category: 'Company'
								},
								render: NameSelector,
							},
							{
								label: 'MIMS药物分类',
								type: 'dropdown',
								render: DrugSelector
							},
						],
					},
					{
						label: '疾病指南',
						link: $constants.routers.diseaseName.link,
						icon: 1,
						children: [
							// {
							//   label: 'MIMS疾病指南',
							//   type: 'dropdown',
							//   data: { category: 'Brand' },
							//   render: NameSelector,
							// },
						],
					},
					{
						label: '权威原版',
						icon: 1,
						children: [],
						link: $constants.routers.authority.link
					},
					{
						label: '新闻资讯',
						link: $constants.routers.news.link,
						children: [{
								label: '会议',
								link: $constants.routers.meeting.link
							},
							{
								label: '热点',
								link: $constants.routers.news.link + '?CategoryType=3&CategoryId=0'
							}
						],
					},
					{
						label: '临床学院',
						children: []
					},
					{
						label: '美迪会',
						link: $constants.routers.club.link,
						children: []
					},
				],
			};
		},
		computed: {
			...mapState({
				user: state => state.user,
				app: state => state.app,
			}),
			username() {
				const {
					userInfo
				} = this.user;
				return userInfo.NickName || userInfo.Uid;
			},
			showDepartments() {
				return location.pathname !== this.$constants.routers.clinical.link || location.pathname === '/';
			},
			activeMenuIdx() {
				return this.menu.findIndex(
					i =>
					(i.link && i.link.startsWith(location.pathname)) ||
					(i.children || []).some(i => i.link && i.link.startsWith(location.pathname))
				);
			},
			showSubMenuIdx() {
				return this.currentMenuIdx !== '' ? this.currentMenuIdx : this.activeMenuIdx;
			},
			pathname() {
				return location.pathname;
			},
			specialtyId() {
				let {
					id
				} = query2Obj(location.href);
				return location.pathname === this.$constants.routers.specialty.link ? id : '';
			},
		},
		created() {
			this.getUserInfoInternal();
			this.getSpecial()
				.then(() => (this.loadingSpecial = false))
				.catch(() => (this.loadingSpecial = false));
			this.getCollege();
		},
		mounted() {
			console.log(location);
		},
		methods: {
			...mapActions('user', ['getUserInfo']),
			...mapActions('app', ['getSpecial']),
			querySearchAsync(searchval, cb) {
				if (this.searchType == 3) {
				var shape=[{
						value: '椭圆形',
					},{
						value: '胶囊形',
					},{
						value: '圆形',
					},{
						value: '六边形',
					},{
						value: '长圆形',
					},{
						value: '三角形',
					},
					// {
					// 	value: '未定义',
					// },
					// {
					// 	value: '半圆形',
					// },{
					// 	value: '双圆形',
					// },{
					// 	value: '梯形',
					// },{
					// 	value: '三叶或四叶形',
					// },{
					// 	value: '齿轮形',
					// },{
					// 	value: '方形',
					// },{
					// 	value: '泪滴形',
					// },{
					// 	value: '盾牌形',
					// },{
					// 	value: '未定形',
					// },{
					// 	value: '锯齿形',
					// },{
					// 	value: '心形',
					// },{
					// 	value: '长方形',
					// },{
					// 	value: '八边形',
					// },{
					// 	value: '任意形状',
					// },{
					// 	value: '七边形',
					// },{
					// 	value: '未定义',
					// },{
					// 	value: '混合包装/整包装',
					// },{
					// 	value: '菱形',
					// },{
					// 	value: '不规则形',
					// },{
					// 	value: '五边形',
					// },{
					// 	value: '弹头形',
					// },

				];
				return cb(shape);
				}
				if (this.searchType == 4) {
				var color=[{
						value: '棕色',
					},{
						value: '橙色',
					},{
						value: '灰色',
					},{
						value: '米色',
					},{
						value: '粉色',
					},{
						value: '紫色',
					},{
						value: '红色',
					},{
						value: '绿色',
					},{
						value: '蓝色',
					},{
						value: '黄色',
					},{
						value: '无色',
					},
				];
				return cb(color);
				}
				if (searchval === '') return cb([]);
				const {
					searchType
				} = this;
				// const api =
				//   +searchType === 1
				//     ? this.$apis.app.getSearchassist({ searchval })
				//     : this.$apis.app.getInfoSearchKey({ search: searchval });
			
				var api = "";
			
				if (searchType == 1) {
					api = this.$apis.app.getSearchassist({
						searchval
					});
				} else if (searchType == 2) {
					api = this.$apis.app.getInfoSearchKey({
						search: searchval
					});
				}
				api
					.then(res => {
							cb(res);
				
					})
					.catch(() => {
						cb([]);
					});
			},
			getUserInfoInternal() {
				this.loadingUserInfo = true;
				this.getUserInfo()
					.then(() => {
						this.loadingUserInfo = false;
					})
					.catch(err => {
						console.error(err);
						this.loadingUserInfo = false;
						this.$utils.checkGoLogin();
					});
			},
			go2link(link) {
				if (link) {
					location.href = link;
				}
			},
			go2search(e) {
				const {
					searchType,
					keywords
				} = this;
				let valid = keywords !== '';
				if (e && e.keyCode && +e.keyCode !== 13) {
					valid = false;
				}
				if (valid) {
					location.assign(
						`${this.$constants.routers.search.link}?keywords=${encodeURIComponent(
            this.keywords
          )}&searchType=${searchType}`
					);
				}
			},
			doLogout() {
				this.$store.dispatch('user/logout').then(() => {
					this.$message.success('退出成功');
				});
			},
			getCollege() {
				this.$apis.train.getCollege().then(res => {
					const menu = this.menu[4];
					let {
						id
					} = query2Obj(location.href);
					menu.children = res.data.map(item => {
						const link = this.$constants.routers.clinical.link;
						// console.log(link === location.pathname, +id === +item.Id);
						return {
							label: item.Name,
							link: `${link}?id=${item.Id}`,
							isActive: link === location.pathname && +id === +item.Id,
						};
					});
					menu.link = menu.children[0].link;
				});
			},
		},
		watch: {
			'searchType': {
				handler: function() {
					this.searchType==3?this.pla="输入三角形，圆形，方形等":this.pla="输入你要搜索条件";
					this.searchType==4?this.pla="输入红色,白色,灰色等":"";
					this.keywords="";
				}
			}
		}
	};
</script>
<style lang="scss">
	.header-menu-popover {
		padding: 0;
		min-width: 10px !important;
		transform: translate(0, -5px);
	}
</style>
<style scoped lang="scss">
	@import '../../../assets/css/variables.scss';

	.main-menu {
		width: 1200px;
		margin: 0 auto;
		flex: 1;
	}

	.sub-dropdown {
		::v-deep {
			.el-icon-arrow-down {
				display: none;
			}

			.el-dropdown-link {
				display: block;
			}
		}
	}

	.item-inner {
		min-width: 120px;
		height: 100%;
		padding: 0 20px;
		text-align: left;

		&.icon {
			padding: 0 20px 0 30px;

			&:before {
				pointer-events: none;
				content: '';
				width: 17px;
				height: 17px;
				background: url('../../../assets/image/icon-nav.png') no-repeat;
				background-size: contain;
				position: absolute;
				left: 10px;
				top: 10px;
			}
		}
	}

	.main-menu-item {
		height: 100%;
		min-width: 110px;
		color: #fff;

		&.current {
			background: #a3010d;
		}
	}

	.sub-menu {
		width: 100%;
		left: 0;
		top: 38px;
		height: 38px;
		background: #fff;
		z-index: 9;
	}

	.sub-menu-inner {
		background: rgba(236, 236, 236, 1);
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		font-size: 14px;

		a {

			&.actived,
			&:hover {
				color: $color-primary;
				font-weight: bold;
			}
		}
	}

	::v-deep .el-input-group__append {
		background: #e4e4e4;
	}

	.search-type {
		display: flex;
		color: $font-sec;

		.item {
			&.active {
				color: $font-main;
				font-weight: bold;
			}

			&:last-child {
				.line {
					display: none;
				}
			}
		}
	}

	::v-deep .el-input__inner {
		background: #e4e4e4;

		&::-webkit-input-placeholder {
			/* WebKit browsers */
			color: #666;
			font-size: 14px;
		}

		&::-moz-placeholder {
			/* Mozilla Firefox 19+ */
			color: #666;
			font-size: 14px;
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10+ */
			color: #666;
			font-size: 14px;
		}
	}

	::v-deep .el-input__prefix,
	::v-deep .el-input__suffix {
		color: #666;
	}

	.header {
		.main {
			display: flex;

			.logo {
				width: 226px;
				height: 70px;
				padding-bottom: 16px;

				.img {
					width: 100%;
				}
			}

			.full {
				flex: 1;
			}

			.search-module {
				width: 380px;

				.type {
					display: flex;

					.info {
						flex: 1;
						color: $font-sec;
						font-size: 12px;
					}
				}
			}
		}

		.dept-list {
			height: 30px;
			line-height: 30px;
			font-size: 14px;
			color: #000;
			border-top: 2px solid $theme-red;
			display: flex;

			::v-deep {
				.el-loading-spinner {
					svg {
						margin: 8px 0 0;
					}
				}
			}

			.item {
				display: inline-block;
				height: 100%;
				padding: 0 15px;

				&:hover {
					font-weight: bold;

					a {
						color: $theme-color;
					}
				}

				&.active {
					color: $theme-color;

					a {
						color: $theme-color;
						font-weight: bold;
					}
				}
			}
		}

		.main-nav {
			line-height: 38px;
			background: $theme-color;
			color: #fff;
			font-size: 15px;

			.login {
				.item {
					padding: 0 10px;
					font-size: 14px;

					&:first-child {
						/*border-right: 1px solid #fff;*/
						position: relative;

						&:after {
							content: '';
							position: absolute;
							width: 2px;
							top: 10px;
							bottom: 10px;
							right: 0;
							background: #fff;
						}
					}

					a {
						color: #fff;
					}

					.svg-icon {
						width: 1.5em;
						height: 1.5em;
						margin-right: 6px;
						padding-top: 3px;
					}
				}
			}
		}
	}
</style>
