<template>
  <div class="sign-in mt4">
    <a :href="ad.TargetUrl" target="_blank">
      <img :src="ad.ImagePath" alt="签到" class="banner"/>
    </a>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    ad: { type: Object, default: () => ({}) },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.sign-in {
  @include padding-height(20%);
  background: $skeleton-bg;
  .banner {
    width: 100%;
  }
}
</style>
