<template>
  <!--右侧模块-通用header-->
  <div class="article-header">
    <span class="title">{{ title }}</span>
    <span class="more" v-if="more"><span class="csp" v-on:click="$emit('click')">更多</span></span>
  </div>
</template>

<script>
export default {
  name: 'ArticleHeader',
  props: {
    title: String,
    more: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/variables.scss';
.article-header {
  font-size: 15px;
  color: $font-main;
  margin-top: 22px;
  margin-bottom: 2px;
  border-bottom: 1px solid $article-title-border;
  display: flex;
  .title {
    position: relative;
    line-height: 32px;
    &:after {
      content: '';
      height: 3px;
      background: $theme-red;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
    }
  }
  .more {
    flex: 1;
    color: $font-sec;
    font-size: 12px;
    line-height: 32px;
    text-align: right;
  }
}
</style>
