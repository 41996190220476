<template>
  <el-input type="text" placeholder="手机号" size="small" :value="value" @input="onChange" maxlength="11">
    <el-button size="small" slot="append" :disabled="showCountdown" @click="doCountdown">{{
      showCountdown ? `${timeLeft}秒后发送` : '发送验证码'
    }}</el-button>
  </el-input>
</template>
<script>
export default {
  name: '',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: '' },
  },
  data() {
    return {
      timeLeft: 60,
      showCountdown: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onChange(v) {
      this.$emit('change', v);
    },
    doCountdown() {
      this.$apis.user.sendcode({ mobile: this.value }).then(() => {
        this.$message.success('验证码发送成功');
        this.$emit('codeSended');
        this.showCountdown = true;
        this.timer = setInterval(() => {
          --this.timeLeft;
          if (this.timeLeft <= 0) {
            clearInterval(this.timer);
            this.showCountdown = false;
            this.timeLeft = 60;
          }
        }, 1000);
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
