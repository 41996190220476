import $http from '@/utils/request';
import $apis from './api';

export const getTrainCourse = params => {
  return $http.get($apis.traincourse, { params });
};

export const getTrainDetail = params => {
  return $http.get($apis.traincourseDetail, { params });
};

export const getCollege = params => {
  return $http.get($apis.college, { params });
};

export const getCollegeDetail = params => {
  return $http.get($apis.getCollegeDetail, { params });
};

export const trainCourseHot = params => {
  return $http.get($apis.trainCourseHot, { params });
};
